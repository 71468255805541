import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface ICart {
  recentlyViewedProducts: string[];
}

const initialState: ICart = {
  recentlyViewedProducts: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    addToRecentlyViewed: (state, { payload }: PayloadAction<any>) => {
      const isAlreadyAdded = state.recentlyViewedProducts.find(
        (item) => payload === item
      );

      if (!isAlreadyAdded) {
        state.recentlyViewedProducts = [
          payload,
          ...state.recentlyViewedProducts,
        ];
        return;
      }

      return;
    },
  },
});

export const { addToRecentlyViewed } = productSlice.actions;

export default productSlice.reducer;
