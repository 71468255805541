import { useQuery } from "@apollo/client";
import {
  GET_DELIVERY_LOCATION,
  GET_PICKUP_LOCATIONS,
} from "../graphql/queries/queries";
import {
  DeliveryLocationsQuery,
  DeliveryLocationsQueryVariables,
  GetPickUpLocationsQuery,
  GetPickUpLocationsQueryVariables,
} from "../graphql/queries/__generated__/queries";

const useDeliveryLocations = (variables?: DeliveryLocationsQueryVariables) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<
    DeliveryLocationsQuery,
    DeliveryLocationsQueryVariables
  >(GET_DELIVERY_LOCATION, {
    fetchPolicy: "network-only",
    variables,
  });

  return {
    deliveryLocations: data?.deliveryLocations ?? [],
    loading,
    error,
    refetch,
    fetchMore,
  };
};

export const usePickupLocations = (
  variables?: GetPickUpLocationsQueryVariables
) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<
    GetPickUpLocationsQuery,
    GetPickUpLocationsQueryVariables
  >(GET_PICKUP_LOCATIONS, {
    fetchPolicy: "network-only",
    variables,
  });

  return {
    pickupLocations: data?.getPickUpLocations ?? [],
    loading,
    error,
    refetch,
    fetchMore,
  };
};

export default useDeliveryLocations;
