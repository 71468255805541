import { useQuery } from "@apollo/client"
import { GET_SHOP_COLLECTIONS } from "../graphql/queries/queries"
import { GetShopCollectionsQuery, GetShopCollectionsQueryVariables } from "../graphql/queries/__generated__/queries"

export const useShopCollections = (variables?: GetShopCollectionsQueryVariables) => {
    const { data, loading, error,  networkStatus, fetchMore } = useQuery<GetShopCollectionsQuery, GetShopCollectionsQueryVariables>(GET_SHOP_COLLECTIONS, {
        variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })
    return {
        collections: data?.getShopCollections ?? [],
        loading,
        error,
        networkStatus,
        fetchMore
    }
}