import { useQuery } from "@apollo/client";
import { GET_ORDERS } from "../graphql/queries/queries";
import { GetOrdersQuery, GetOrdersQueryVariables } from "../graphql/queries/__generated__/queries";


const useFetchOrders = (variables?: GetOrdersQueryVariables) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<GetOrdersQuery, GetOrdersQueryVariables>(GET_ORDERS, {
    fetchPolicy: "network-only",
    variables
  });

  return {
    orders: data?.getOrders ?? [],
    loading,
    error,
    refetch,
    fetchMore
  };
};

export default useFetchOrders;
