import { DukePaymentProps } from "./types";
// import usePaystackScript from './paystack-script';
import { useMutation } from "@apollo/client";
import {
  GetCheckOutLInkMutation,
  GetCheckOutLInkMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { getCheckoutLink } from "../../graphql/mutations/mutations";
import { ReasonForPayment } from "../../graphql/types";

export default function usePaystackPayment(options: DukePaymentProps) {
  const { firstName, lastName, billingEmail, phone, amount ,orderId} = options;
  console.log(billingEmail);
  const [execute, { loading, data }] = useMutation<
    GetCheckOutLInkMutation,
    GetCheckOutLInkMutationVariables
  >(getCheckoutLink);

  function initializePayment(): void {
    execute({
      variables: {
        data: {
          customer_email: billingEmail ?? "",
          customer_first_name: firstName ?? "",
          customer_last_name: lastName ?? "",
          phone: phone ?? "",
          request_amount: process.env.NODE_ENV === "development" ? 1 : amount,
          reasonForPayment: ReasonForPayment.OrderPurchase,
          shopaOrderId: orderId ?? ""

        },
      },
    });
  }
  return { initializePayment, loading, checkoutLink: data?.makePayment };
}
