import { RadioGroup } from "@headlessui/react";
import {
  ArrowRightIcon,
  CheckIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import React, {  useEffect, useState } from "react";
import useCustomer from "../../hooks/useCustomer";
import AddAddress from "./components/AddAddressForm";
import Modal from "../../components/Modal";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Link } from "react-router-dom";
import { setCustomerAddress } from "../../features/cart/cart-slice";


type Props = {};

const AddressPage = (props: Props) => {
  const [plan, setPlan] = useState("");
   const { userInfo } = useAppSelector(state => state.auth)
  const [openAddAddressModal, setAddAddressModal] = useState(false);
  const dispatch = useAppDispatch();
  const { customer} = useCustomer({
    filter: { phone: { eq: userInfo?.phone} },
    
  });

  const handleAddressChange = (addressId: any) => {
    dispatch(setCustomerAddress(addressId));
    console.log(addressId);
  };

  useEffect(() => { }, []);

  return (
    <div className="space-y-4 w-full ">
      <div className="flex items-center space-x-2 mb-11">
        <h3 className="small-caps font-extralight text-xl text-gray-300">
          Cart
        </h3>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <Link to="/cart/shippinginfo">
        <h3 className="small-caps font-extralight text-xl text-gray-300 hover:text-orange-500">
            Contact Info
          </h3>
          </Link>
          <ArrowRightIcon className="text-gray-300 h-6" />
        <h1 className="font-bold text-xl">Shipping</h1>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h3 className="small-caps font-extralight text-xl text-gray-300">
          Delivery
        </h3>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h3 className="small-caps font-extralight text-xl text-gray-300">
          Payment
        </h3>
      </div>
      { customer?.address?.length!>0 ?(
        <span className="font-light text-sm">We securely fetched these addresses from your recently used addresses. Select one of these addresses or add a new one below</span>
      ):(
        <div>
          <span className="font-light text-sm">We securely fetched these addresses from your recently used addresses. Select one of these addresses or add a new one below</span>
        </div>
      )

      }
      <RadioGroup   onChange={handleAddressChange} className="space-y-5 ">
        {customer?.address?.map((address) => (
          
          <RadioGroup.Option   value={address?.id} className="ring-1 ring-gray-300 checked:ring-pink-600 rounded-md py-4 pl-2">
            {({ checked }) => (
              <div className="flex justify-between cursor-pointer">
                <div className="flex items-start space-x-3 ">
                  <div className="shrink-0 text-black border-2 bg-white mt-2 checked:bg-pink-600  h-4 w-4 rounded-full flex items-center justify-center">

                    {checked ? (
                      <div className="shrink-0 text-pink-600 border bg-pink-600   h-4 w-4 rounded-full flex items-center justify-center">
                      <CheckIcon className="h-2 w-2  text-white " />
                      </div>
                    ) : null}
                  </div>

                  <div className="flex flex-col -pt-4">
                    <span>{address?.area}</span>
                    <span>{address?.city}</span>
                    <span>{address?.country ?? ""}</span>
                  </div>
                </div>
              </div>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>
      <hr />
      <button className="flex items-center text-teal-500 space-x-4">
        <PlusIcon className="h-6 w-6 text-pink-600" />
        <button
              
              className="text-md text-pink-600 hover:text-gray-100"
              onClick={() => setAddAddressModal(true)}
            >
              Add new Address
            </button>
      </button>
      <Modal title="Add New Address" showCloseIcon show={openAddAddressModal} setShow={setAddAddressModal}>
        <AddAddress setShow={setAddAddressModal} />
      </Modal>
    </div>

  );
};

export default AddressPage;


