import Button from "../buttons/buttons";
// import ErrorMessage from "../ErrorMessage/ErrorMessage";
// import Modal from "../Modal";
import TextInput from "../TextInput/TextInput";
import { SwapSpinner } from "react-spinners-kit";
import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";

import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  CustomerSignUpMutationVariables,
  CustomerSignUpMutation,
} from "../../graphql/mutations/__generated__/mutations";
import { signinUser } from "../../features/auth/auth-slice";
// import { signUp } from "../../features/auth/auth-slice";
interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setShowSignIn:React.Dispatch<React.SetStateAction<boolean>>;
}
const CreateAccount = ({ setShow ,setShowSignIn}: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    
  } = useForm<CustomerSignUpMutationVariables>();
  const [createAccount, { loading }] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = (values) => {
    createAccount({
      variables: {
        ...values,
        userId: shop?.ownerId ?? "",
      },
      onCompleted: (res) => {
        setShowSignIn(false);
        if (res?.customerSignUp) {
          console.log(res.customerSignUp);
          dispatch(signinUser(res?.customerSignUp as any));
          setShow(false);
          setShowSignIn(false);
          //   setLogin(true);
          navigate("/");

          return;
        } else {
          notification.error({
            message: "please contact support",
          });
          return;
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };
  return (
    <React.Fragment>
      <h4 className="text-base text-tendo-200 my-2">Sign Up</h4>

      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="flex justify-between"> */}
        <div className="mb-6">
          <label
            htmlFor="fullname"
            className="block text-sm font-medium  lg:text-black "
          >
            Full name
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.name", { required: true })}
              type="text"
              required
              id="fullname"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="First name"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
          </div>
        </div>
        {/* <div className="mb-6">
          <label
            htmlFor="lastname"
            className="block text-sm font-medium lg:text-black "
          >
            Last name
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
             {...register("input.phone", { required: true })}
             type="text"
             required
              id="lastname"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="Last name"
            
            /> 
             {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
        {/* </div>
        </div>
        </div>  */}
        <div className="mb-6">
          <label
            htmlFor="phone"
            className="block text-sm font-medium lg:text-black "
          >
            Phone number
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.phone", { required: true })}
              type="text"
              required
              id="phone"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder=" 0202718168"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block text-sm font-medium lg:text-black "
          >
            Email
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.email", { required: true })}
              type="email"
              required
              id="email"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder=" example@gmail.com"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="city"
            className="block text-sm font-medium lg:text-black "
          >
            City
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.address.city", { required: true })}
              type="text"
              required
              id="city"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="City"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="area"
            className="block text-sm font-medium lg:text-black "
          >
            Area
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.address.area", { required: true })}
              type="text"
              required
              id="area"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="Area"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="landmark"
            className="block text-sm font-medium lg:text-black "
          >
            Landmark
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
              {...register("input.address.landmark", { required: true })}
              type="text"
              required
              id="landmark"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="Landmark"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>

        {/* <div className="mb-6">
          <label
            htmlFor="comment"
            className="block text-sm font-medium text-gray-700"
          >
            Description
          </label>
          <div className="mt-1">
            <textarea
              rows={2}
              id="comment"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="eg. This is the description for the category"
            //   {...register("description")}
            />
          </div>
        </div> */}

        <div className="flex justify-end space-x-4">
          <Button
            disabled={loading}
            onClick={() => setShow(false)}
            type="button"
          >
            Cancel
          </Button>

          <button
            disabled={loading}
            type="submit"
            className="disabled:bg-gray-300 bg-amber-500 disabled:cursor-wait  flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
          >
            {loading && <SwapSpinner size={45} color="white" />}
            {!loading && <p>Submit</p>}
          </button>
        </div>
      </form>
    </React.Fragment>
  );
};

export default CreateAccount;
