import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { useShopCollections } from "../../../hooks/useShopCollections";
import { useAppSelector } from "../../../app/hooks";

type Props = {};

const Collections = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { collections } = useShopCollections({
    filter: {
      shop_id: {
        eq: shop?.id,
      },
    },
  });

  if (collections.length < 1) return null;

  return (
    <Fragment>
      {/* <!-- Collection section --> */}
      <section
        aria-labelledby="collection-heading"
        className="mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8"
      >
        <h2
          id="collection-heading"
          className="text-2xl font-bold tracking-tight text-gray-900 "
        >
          Collections
        </h2>
       
        <div className="mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0">
          {collections?.map((collection, index) => (
            <Link
              to={{
                pathname: `collection/${collection?.id}`,
              }}
              className="group block"
            >
             
              <div
                aria-hidden="true"
                className="aspect-w-3 aspect-h-2 overflow-hidden rounded-lg group-hover:opacity-75 lg:aspect-w-5 lg:aspect-h-6"
              >
                <div className="absolute inset-0 bg-black bg-opacity-30 flex justify-center z-10 text-white items-center w-full m-auto"> 
                <div className="border-white border-2 bg-white bg-opacity-50 text-center w-32 h-8 rounded-md">test</div>
                 </div>
                <img
                  src={
                    collection?.banner ??
                    "https://tailwindui.com/img/ecommerce-images/home-page-01-collection-01.jpg"
                  }
                  alt="Brown leather key ring with brass metal loops and rivets on wood table."
                  className="h-full w-full object-cover object-center"
                />
              </div>
              
            </Link>
          ))}
        </div>
      </section>
    </Fragment>
  );
};

export default Collections;
