
import { useNavigate } from "react-router-dom";
import Footer from "../../components/footer";
import Header from "../../components/header";
import useCart from "../../hooks/useCart";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useCustomer from "../../hooks/useCustomer";
import { setCustomerAddress } from "../../features/cart/cart-slice";

import {
  setCustomerDeliveryLocation,
  clearCart,
} from "../../features/cart/cart-slice";

import { useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../../graphql/mutations/mutations";
import useDeliveryLocations from "../../hooks/useDeliveryLocations";

import {
  CreateOrderMutation,
  CreateOrderMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";

type Props = {};

const CheckoutComponent = (props: Props) => {
  const navigate = useNavigate();
  const {
    cartItems,

    total,
    customer_address,

  } = useCart();
  const { shop } = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();
  const [execute] = useMutation<
    CreateOrderMutation,
    CreateOrderMutationVariables
  >(CREATE_ORDER);
  const [tempId, setTempID] = useState<string>("");
  const { userInfo } = useAppSelector((state) => state.auth);
  const { customer } = useCustomer({
    filter: {
      id: { eq: userInfo?.id },
    },
  });

  const { deliveryLocations } = useDeliveryLocations({
    filter: {
      id: {
        eq: userInfo?.id,
      },
    },
  });
  console.log(deliveryLocations);

  const { register, handleSubmit } = useForm<any>({
    defaultValues: {
      input: {
        name: userInfo?.name,
        phone: userInfo?.phone,
      },
    },
  });


  const selectedDeliveryLocation = _.find(
    deliveryLocations,
    (e) => e?.id === tempId
  );

  useEffect(() => {
    if (selectedDeliveryLocation) {
      dispatch(setCustomerDeliveryLocation(selectedDeliveryLocation));
    }
  }, [selectedDeliveryLocation, dispatch]);

  return (
    <div className="bg-white">
      <Header />
      <div className="mb-6">
        <div className="bg-white">
          <div className="max-w-2xl mx-auto pt-2 md:pt-16  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            {/* <select name="" id="" 
            className="appearance-none bg-mainPink text-white outline-0 rounded-sm pl-4 "
            onChange={(evt)=>dispatch(setCustomerDeliveryLocation(evt.target.value))}
            > 
            <option value="" className="bg-white text-black hover:bg-mainPink">Select delivery zone</option>
            {deliveryLocations?.map((deliveryLocation)=>
            (
              <option value={deliveryLocation?.id} className="bg-white  text-black rounded-sm  hover:bg-mainPink"> {deliveryLocation?.location} - {deliveryLocation?.price}</option>
            )
            )}
            </select>
             */}

            <div
              className="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
            >
              <div>
                <div>
                  <h2 className="text-lg font-medium text-gray-900">
                    Shipping information
                  </h2>
                  <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          {...register("input.name", {})}
                          type="text"
                          className=" py-3 focus:outline-none sm:text-sm block w-full rounded-md border border-gray-300 shadow-sm"
                        />
                        {/* <ErrorMessage errors={errors} name="input.name" /> */}
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Phone
                      </label>
                      <div className="mt-1">
                        <input
                          {...register("input.phone", {})}
                          type="text"
                          className="py-3 focus:outline-none sm:text-sm block w-full rounded-md border border-gray-300 shadow-sm  "
                        />
                        {/* <ErrorMessage errors={errors} name="input.phone" /> */}
                      </div>
                    </div>
                  </div>
                </div>

                {/* delivery locations */}
                <div className="mt-10">
                  <label htmlFor="">Choose Delivery Zone {tempId}</label>
                  <select
                    name=""
                    id=""
                    className="placeholder:inter-base-regular placeholder-grey-40 py-3 border focus:outline-none sm:text-sm block w-full rounded-md  shadow-md"
                    onChange={(evt) => setTempID(evt.target.value)}
                  >
                    <option value="">Select delivery zone</option>
                    {deliveryLocations?.map((deliveryLocation) => (
                      <option value={deliveryLocation?.id}>
                        {" "}
                        {deliveryLocation?.location} - {deliveryLocation?.price}
                      </option>
                    ))}
                  </select>
                </div>

                {/* addresses */}
                <div>
                  <div className="text-sm pt-6 pb-4">Choose an address</div>
                  {customer?.address?.map((address) => {
                    return (
                      <div
                        onClick={() => dispatch(setCustomerAddress(address))}
                        key={customer?.id}
                        className={`relative flex flex-row w-52  h-20 mb-4 items-center  pace-x-3 rounded-lg border border-gray-300 ${customer_address?.id === address?.id
                          ? "bg-blue-400 "
                          : "bg-white"
                          } px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-400 focus-within:ring-offset-2 hover:border-gray-400`}
                      >
                        <div className="min-w-0 flex-1">
                          <button className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            <p className="text-sm font-medium text-gray-900">
                              <span className="mr-2">City:</span>
                              {address?.city}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              <span className="mr-2">Area:</span>
                              {address?.area}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              <span className="mr-2 text-sm">Landmark:</span>
                              {address?.landmark}
                            </p>
                            <p className="truncate text-sm text-gray-500">
                              {address?.country}
                            </p>
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className="mt-10 lg:mt-0">
                <h2 className="text-lg font-medium text-gray-900">
                  Order summary
                </h2>

                <div className="mt-4 bg-white border border-gray-200 rounded-lg shadow-sm">
                  <ul className="divide-y divide-gray-200">
                    {cartItems.map((product, index) => (
                      <li key={index} className="flex  p-3">
                        <div className="flex-shrink-0">
                          <img
                            src={product.avatar}
                            alt={product.title}
                            className="w-10 h-10 rounded-md"
                          />
                        </div>

                        <div className="ml-6 flex-1 flex flex-col">
                          <div className="flex">
                            <div className="min-w-0 flex-1">
                              <h4 className="text-sm">
                                <span className="font-medium text-xs text-gray-700 hover:text-gray-800">
                                  {product?.title}
                                </span>
                              </h4>
                              <p className="-mt-2 text-sm text-gray-500">
                                {product.combination_string
                                  ?.split("&")
                                  .join(" ")}
                              </p>
                            </div>
                          </div>

                          <div className="flex-1 pt-2 flex items-end justify-between">
                            <p className="mt-1 text-xs font-medium text-gray-900">
                              {product.currency}{" "}
                              {_.sum([product?.price, product?.profit_added])}
                            </p>
                          </div>
                          <div className="mt-6 mr-6 -mb-6 -ml-12">
                            <div className="flex flex-row justify-between">
                              <div>Subtotal</div>
                              <div>
                                {shop?.currency} {total}
                              </div>
                            </div>
                            <div className="flex flex-row justify-between">
                              <div>Delivery</div>
                              <div>
                                {shop?.currency}{" "}
                                {selectedDeliveryLocation?.price}
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <dl className="py-6 px-4 space-y-6 sm:px-6">
                    <div className="flex  items-center justify-between border-t border-gray-200 pt-6">
                      <div>
                        <dt className="text-base font-bold">Total</dt>
                      </div>

                      <div>
                        <dd className="text-base font-bold text-gray-900">
                          {shop?.currency}{" "}
                          {_.sum([total, selectedDeliveryLocation?.price])}
                        </dd>
                      </div>
                    </div>
                  </dl>

                  <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
                    <button
                      // disabled={loading}
                      onClick={() =>
                        navigate("/confirmpayment", {
                          state: selectedDeliveryLocation?.price,
                        })
                      }
                      type="submit"
                      className="disabled:bg-gray-500 disabled: w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      <span>checkout</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutComponent;
