import React, {

  Fragment,
  ReactNode,

  useEffect,


} from "react";
import usePaystackPayment from "./use-dukePayment";
import { DukePaymentProps } from "./types";
import clsx from "clsx";
// import { Modal } from "@mantine/core";
// import Iframe from "react-iframe";

interface DukePaymentButtonProps extends DukePaymentProps {
  text?: string;
  className?: string;
  children?: ReactNode;
}

const DukePaymentButton = ({
  text,
  className,
  children,
  onSuccess,
  onCancel,
  ...others
}: DukePaymentButtonProps): JSX.Element => {
  // const [showPaymentModal, setShowPaymentModal] = useState(false);
  // const [url, setUrl] = useState<any>("");
  const { initializePayment, loading, checkoutLink } = usePaystackPayment(
    others as any
  );

  useEffect(() => {
    if (checkoutLink) {
      console.log(checkoutLink)
      window.location.href = checkoutLink.concat(
        `&redirect=${window.location.protocol.concat(
          "//" + window.location.host
        )}/confirm-payment`
      );
    }
  }, [checkoutLink]);

  return (
    <Fragment>
      <button
        disabled={loading}
        className={clsx(
          className,
          "disabled:bg-gray-500 disabled:cursor-not-allowed"
        )}
        onClick={(): void => initializePayment()}
      >
        {text || children}
      </button>
    </Fragment>
  );
};

export default DukePaymentButton;

//https://checkout.dukesell.com/GHS/0506339153?data=eyJjdXN0b21lcl9lbWFpbCI6ImViYWlkb283OUBnbWFpbC5jb20iLCJjdXN0b21lcl9maXJzdF9uYW1lIjoiRW1tYW51ZWwiLCJjdXN0b21lcl9sYXN0X25hbWUiOiJCYWlkb28iLCJhbW91bnQiOjF9&redirect=http://fuchsia-baidoo.localhost:3000/cart?success=false
