import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useProduct } from "../../hooks/useProduct";
import useProductReviews from "../../hooks/useProductReviews";
import useReviewLength from "../../hooks/useReviewLength";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  addToCart,
  decreaseQty,
  increaseQty,
  removeFromCart,
  setCustomerAddress,
} from "../../features/cart/cart-slice";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import numeral from "numeral";
import { Fragment, useState } from "react";
import { Dialog } from "@headlessui/react";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { pdfjs, Document, Page } from "react-pdf";
import PdfViewerComponent from "../../components/PdfViewerComponent";
import {defaultLayoutPlugin} from '@react-pdf-viewer/default-layout';

const PreviewDigitalProduct: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { shop } = useAppSelector((state) => state?.shop);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false); // Modal state

  // Function to toggle modal
  const openPreviewModal = () => setIsPreviewOpen(true);
  const closePreviewModal = () => setIsPreviewOpen(false);
  const { product } = useProduct({
    filter: {
      id: {
        eq: id,
      },
    },
  });
  const productid = product?.id;
  console.log(productid);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  // const downloadFile = (href:string,filename:string) => {
  //   const link = document.createElement('a');
  //   link.href = href;
  //   link.download = filename;
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const downloadFile = async (href: string, filename: string) => {
    try {
      const response = await fetch(
        `https://media.tendo.africa/api/v1/soko/media/download?key=${href}`
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();

      // Clean up
      link.parentNode!.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the file:", error);
    }
  };
  const goHome = () => {
    navigate("/");
  };
  const renderPreviewContent = () => {
    switch (product?.digitalDetails?.type) {
      case "video":
        return (
          <video width="100%" controls>
            <source
              src={product?.digitalDetails?.attachedFiles}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        );

      case "audio":
        return (
          <audio controls>
            <source
              src={product?.digitalDetails?.attachedFiles}
              type="audio/mpeg"
            />
            Your browser does not support the audio tag.
          </audio>
        );

      case "pdf":
        return (
          <div className="overflow-auto h-[500px] sm:w-[500px] w-[300px]">
            {product?.digitalDetails?.attachedFiles && (
              <Worker  workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer fileUrl={product?.digitalDetails?.attachedFiles[0]} plugins={[defaultLayoutPluginInstance]} />;
              </Worker>
            )}
          </div>
        );
      default:
        return <p>Unsupported content type</p>;
    }
  };

  return (
    <div className="bg-white">
      <Helmet>
        {/* <title> {product?.title}</title> */}
        <link rel="icon" type="image/png" href={product?.avatar} />
        <meta property="og:title" content={product?.title} />
        <meta
          property="og:description"
          content={shop?.name + "'s shop  - " + product?.title}
        />
        <meta property="og:image" itemProp="image" content={product?.avatar} />
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
      </Helmet>
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 sm:border sm:rounded-lg">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
              {product?.media?.map((image: any) => (
                <Tab.Panel key={image?.id}>
                  <img
                    src={image?.avatar ?? ""}
                    alt={product?.title}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 sm:p-4 sm:mt-16  lg:mt-0">
            <h1 className="text-xl lg:text-3xl font-medium tracking-tight text-gray-900">
              {product?.title}
            </h1>

            <div className="mt-3"></div>

            <div className="mt-6">
              <h3 className="font-medium">Product Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: product?.description ?? "" }}
              />
            </div>
            <div className="flex sm:mt-96 mt-24 mb-4 sm:mb-0  float-right flex-row space-x-4">
              <button
                onClick={goHome}
                className="rounded-full border border-gray-400 px-4 py-1.5"
              >
                Back home
              </button>
              <button
                onClick={openPreviewModal}
                className="rounded-full bg-pink-500 text-white px-4 py-1.5"
              >
                Preview
              </button>
            </div>

            <section></section>
          </div>
        </div>
      </div>
      <Dialog
        open={isPreviewOpen}
        onClose={closePreviewModal}
        className="relative z-50"
      >
        {/* Background overlay */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        {/* Modal content */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel className="mx-auto max-w-2xl bg-white  rounded-md border-4 border-white shadow-xl">
            {/* <Dialog.Title className="text-lg font-semibold">
              Preview Book
            </Dialog.Title> */}

            {/* The preview content, like a book page */}
            <div className="mt-4 w-full">
              {/* <p>Here you can add the content of the preview (e.g., the first page of the book).</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p> */}
              {renderPreviewContent()}
            </div>

            <div className="mt-6 flex justify-end space-x-4">
              {product?.digitalDetails?.deliveryMode === "Download" && (
                <button
                  className="rounded-md px-4 py-2 bg-pink-600 text-white hover:bg-gray-400"
                  onClick={() =>
                    downloadFile(
                      product?.digitalDetails?.attachedFiles[0],
                      product?.title
                    )
                  }
                >
                  Download
                </button>
              )}
              <button
                className="rounded-md px-4 py-2 bg-gray-300 hover:bg-gray-400"
                onClick={closePreviewModal}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </div>
  );
};

export default PreviewDigitalProduct;
