import { Drawer } from "antd";
import { useState } from "react";
import Cart from "../cart";
import HeaderComponent from "./HeaderComponent";
type Props = {};

const HeaderContainer = (props: Props) => {
  const [showCartDrawer, setShowCartDrawer] = useState(false);
  return (
    <>
      <HeaderComponent
        showCartDrawer={showCartDrawer}
        setShowCartDrawer={setShowCartDrawer}
      />

      <Drawer
        title={
          <div className="flex justify-between items-center">
            <h1>Shopping Cart</h1>
            <h1
              onClick={() => setShowCartDrawer(false)}
              className="font-semibold text-2xl border border-gray-600 px-2 rounded-full cursor-pointer"
            >
              X
            </h1>
          </div>
        }
        closable={false}
        onClose={() => setShowCartDrawer(false)}
        open={showCartDrawer}
      >
        <Cart />
      </Drawer>
    </>
  );
};

export default HeaderContainer;
