import useFetchOrders from "../../hooks/useFetchOrders";
import { useAppSelector } from "../../app/hooks";
import Table from "./components/table";

type Props = {};

const Orders = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
  const { orders, loading } = useFetchOrders({
    filter: {
      customer_id: {
        eq: userInfo?.id,
      },
    },
  });

  console.log(orders);

  return (
    <>
      <div className="w-full">
        <div>
          <h1 className="text-xl font-extrabold">My Orders</h1>
        </div>
        <Table orders={orders} />
      </div>
    </>
  );
};

export default Orders;
