const Loader = () => {
    return ( 
        <div className="flex justify-center items-center h-screen">
  <div className="animate-spin absolute rounded-full h-32 w-32 border-t-2 border-b-2 border-pink-600">
    
  </div>
  
</div>

     );
}
 
export default Loader;