import { useState } from "react";
import useCart from "../../../hooks/useCart";
import plural from "pluralize";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import Modal from "../../../components/Modal";
import { showNotification } from "@mantine/notifications";
import SignIn from "../../../components/SignIn/SignIn";
import {
    decreaseQty,
    increaseQty,
    removeFromCart,
  } from "../../../features/cart/cart-slice";
import { Link } from "react-router-dom";

interface Props {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
  }

const CartModal = ({ setShow }: Props) => {
    const { cartLength, cartItems } = useCart();
    const [openSignInModal, setSignInModal] = useState(false);
    const [openCartModal, setCartModal] = useState(false);
    const { userInfo } = useAppSelector((state) => state.auth);
    const dispatch = useAppDispatch();
    const openModal=()=>{
        
        setSignInModal(true);
        setCartModal(false);
        
    };
    return ( 
        <div>
            <div className="flex items-end space-x-2 mb-11  ">
        <h1 className="font-bold text-3xl">Cart</h1>
        <h3 className="small-caps font-light text-xl text-gray-500">
          {cartLength} {plural("item", cartLength)}
        </h3>
      </div>

      <div className="py-4 space-y-4 mr-6">
        {cartItems?.map((item, idx) => (
          <div
            key={idx}
            className="flex justify-between flex-col md:flex-row items-start"
          >
            <div className="flex  space-x-6">
              <div className="w-[160px] h-[160px]">
                <img src={item?.avatar} className="h-full w-full" alt="" />
              </div>
              <div className="flex flex-col items-start space-y-4">
                <span>{item?.title}</span>
                <span className="font-medium">
                {item?.currency} {_.sum([item?.price,item?.profit_added])}
                
              </span>
                <span>{item?.combination_string?.split("&").join(", ")}</span>
                
                <div className="flex space-x-2 items-center flex-col lg:flex-row">
                  <div className="border flex justify-between w-36 border-gray-400 p-1 items-center">
                    <div onClick={() => dispatch(decreaseQty(idx))}>
                      <MinusIcon className="h-6 w-6" />
                    </div>
                    <div>{item?.quantity}</div>
                    <div onClick={() => dispatch(increaseQty(idx))}>
                      <PlusIcon className="h-6 w-6" />
                    </div>
                  </div>
                  <button
                    onClick={() => dispatch(removeFromCart(item))}
                    className="text-red-600"
                  >
                    Remove
                  </button>
                </div>

                
              </div>
            </div>
            {/* <div>
              <span>
                {item?.currency} {item?.price}
              </span>
            </div> */}
          </div>
        ))}
      </div>
      <div>
                    {/* <button className="bg-amber-500 p-3 hover:bg-white hover:border hover:border-black hover:text-black text-white rounded-md float-right flex-1">
                    Proceed to checkout
                    </button> */}

                    <Link
                            to="/cart"
                            // onClick={(e) => {
                            //   if (userInfo){
                            //     setShow(false);
                            //     return;}
                            //     else{ 
                            //   e.preventDefault();
                            //   openModal();
                            //   showNotification({
                            //     title: "Sign in Required",
                            //     message:
                            //       "Hey there, you would have to either sign in or create an account to continue",
                            //   });
                            // }}}
                            className="bg-amber-500 p-3 hover:bg-white hover:border hover:border-black hover:text-black text-white rounded-md float-right flex-1"
                          >
                           Proceed to checkout 
                            
                          </Link>
                </div>
                <Modal showCloseIcon show={openSignInModal} setShow={setSignInModal}>
        <SignIn setShowSignIn={setSignInModal} />
      </Modal>
      <Modal showCloseIcon show={openCartModal} setShow={setCartModal}>
        <CartModal setShow={setCartModal} />
      </Modal>
        </div>
     );
}
 
export default CartModal;