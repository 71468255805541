import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearCart } from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";
import { useCreateOrder } from "../../hooks/useCreateOrder";
import Loader from "../../layouts/StoreFront/components/Loader";

const ConfirmPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { createOrder, loading, error } = useCreateOrder();
  const { cartItems, customer_address, customer_delivery_location } = useCart();
  const { shop } = useAppSelector((state) => state.shop);
  const dispatch = useAppDispatch();
  const hasInstantDelivery = cartItems.some(item => item.instantDelivery);
  const[isInstantDelivery,setIsInstantDelivery]=useState(false);

  useEffect(() => {
    if(hasInstantDelivery){
      setIsInstantDelivery(true)
    }
    let isMounted = true;
    if (searchParams.get("success") === "true" && isMounted) {
      const subTotal = cartItems.reduce(
        (acc, curr) =>
          acc +
          _.sum([
            _.multiply(curr.price, curr?.quantity),
            _.multiply(curr?.profit_added ?? 0, curr?.quantity),
          ]),
        0
      );

      const totalAmount = _.sum([
        subTotal,
        parseFloat(customer_delivery_location?.price),
      ])
      //console.log(customer_address);
      //console.log(customer_address?.id);
      createOrder({
        variables: {
          input: {
            customer_id: userInfo?.id ?? "",
            products: cartItems.map((item) => ({
              price: item?.price,
              product_id: item?.sku,
              profitAdded: item?.profit_added ?? 0,
              qty: item?.quantity,
              cost_price: item?.cost_price
            })),
            reseller: shop?.ownerId ?? "",
            total_amount: totalAmount,
            address_id: customer_address,
            delivery_id: customer_delivery_location?.id,
            delivery_type: "DELIVERY",
            discount: 0,
            payment_id: searchParams.get("reference"),
            payment_status: "FULL PAYMENT",
            payment_type: "PON",
            source: "store-front",
            delivery_amount: parseFloat(customer_delivery_location?.price),
            free_delivery: false,
            customer_paid: true,
            instantDelivery:isInstantDelivery

          },
        },
        
      })
      
        .then((res) => {
          if (isMounted) { // Check isMounted before proceeding
            navigate("/checkout/success");
            dispatch(clearCart());
            
          }
        })
        .catch((err) => {
          console.log("--ERROR--", err);
        });
    } else {
      navigate("/");
    }
    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, []);


  if (loading) {
    return <Loader />;
  }
  if (error) {
    return (
      <div className="space-y-4 w-full">
        <h1>Sorry Order placement couldn't go through for a reason.. try again</h1>

      </div>
    );
  }
  return null;
};

export default ConfirmPayment;
