import { useQuery } from "@apollo/client";
import { GET_REVIEWS } from "../graphql/queries/queries";
import { GetReviewsQuery,GetReviewsQueryVariables } from "../graphql/queries/__generated__/queries";
 const useProductReviews = (variables?:GetReviewsQueryVariables) => {
    const { data, loading, error, refetch, fetchMore } = useQuery<GetReviewsQuery, GetReviewsQueryVariables>(GET_REVIEWS, {
        fetchPolicy: "network-only",
        variables
      });
    return {
        reviews: data?.getReviews ?? [],
        loading,
    };
};
export default useProductReviews;
 
