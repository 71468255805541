import {
  CheckIcon,
  MinusIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import plural from "pluralize";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useCart from "../../hooks/useCart";
import Show from "../../components/Show";
import { Helmet } from "react-helmet-async";
import { useLocation, Outlet, useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";
import numeral from "numeral";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import TextInput from "../../components/TextInput/TextInput";
import { SwapSpinner } from "react-spinners-kit";
import { useProduct } from "../../hooks/useProduct";
import {
  CustomerSignUpMutation,
  CustomerSignUpMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { useMutation } from "@apollo/client";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";
import { CREATE_DIGITAL_ORDER } from "../../graphql/mutations/mutations";
import {
  CreateDigitalOrderMutation,
  CreateDigitalOrderMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { notification } from "antd";
import { signinUser } from "../../features/auth/auth-slice";
import { SubmitHandler, useForm } from "react-hook-form";
import DukeDigitalOrderPaymentButton from "../../components/duke-payment/duke-payment-digital-order";
import clsx from "clsx";
const DigitalProductDeliveryPage = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const [isFilled, setIsFilled] = useState(false);
  const checkbox = () => {
    setIsFilled(true);
    handleSubmit(onSubmit)();
  };
  const [emailValue, setEmailValue] = useState("");
  const { id } = useParams();
  const { qty } = useParams();
  const initialQty = Number(qty) || 1;
  const { product } = useProduct({
    filter: {
      id: {
        eq: id,
      },
      // shop: {
      //   shop_id: {
      //     eq: shop?.id,
      //   },
      // },
    },
  });
  const location = useLocation();

  // Extract the pathname from the location object (this is the current route)
  const currentRoute = location.pathname;
  console.log(currentRoute)
  const { cartLength, cartItems, deliveryPrice } = useCart();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [productQty, setProductQty] = useState(initialQty);
  const lastItem = R.last(cartItems);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const productsTotal =
    R.sum([product?.price!, product?.profit_added ?? 0]) * productQty;
  const total = R.sum([
    productsTotal,
    parseFloat(deliveryPrice as unknown as string) || 0,
  ]);
  const { register, handleSubmit } = useForm<CustomerSignUpMutationVariables>();
  const [createAccount, { loading }] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const [createDigitalOrder, { loading: creatingOrder }] = useMutation<
    CreateDigitalOrderMutation,
    CreateDigitalOrderMutationVariables
  >(CREATE_DIGITAL_ORDER);
  const createOrder = () => {
    createDigitalOrder({
      variables: {
        input: {
          customerId: userInfo?.id!,
          deliveryMode: "SMS",
          digitalDetails: "details",
          paymentReference: null,
          paymentStatus: "PAID",
          price: product?.price!,
          productId: product?.id!,
          profitAdded: product?.profit_added || 0,
          resellerId: userInfo?.reseller?.id!,
          status: "PENDING",
          supplierId: product?.supplier_id,
        },
      },
    })
      .then((res) => {
        if (res.data?.createDigitalOrder) {
          navigate(`/confirm-digital-order-payment/${id}/${productQty}`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = (values) => {
    createAccount({
      variables: {
        ...values,
        userId: shop?.ownerId ?? "",
      },
      onCompleted: (res) => {
        // setShowSignIn(false);
        if (res?.customerSignUp) {
          console.log(res.customerSignUp);
          dispatch(signinUser(res?.customerSignUp as any));
          //handlePayment();

          return;
        } else {
          notification.error({
            message: "please contact support",
          });
          return;
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };
  const handleEmailInputChange = (event: any) => {
    const value = event.target.value;
    setEmailValue(value);
    // handleSubmit(onSubmit);

    // if (value.trim() !== "") {
    //   setIsFilled(true);
    //   //handleSubmit(onSubmit)();
    // } else {
    //   setIsFilled(false);
    // }
  };
  // const handleSignUpAndOrder = () => {
  //   handleSubmit(onSubmit)(); // Submit the sign-up form
  // };
  // const handlePayment = () => {
  //   console.log("Triggering payment");
  //   console.log(dukePaymentButtonRef.current);
  //   if (dukePaymentButtonRef.current) {
  //     dukePaymentButtonRef.current.click(); // Trigger payment button click
  //   }
  // };

  return (
    <div className="flex flex-col-reverse sm:flex-row w-full">
      <div className="flex flex-col sm:flex-row w-[80%] mx-auto my-8 justify-between    ">
        <div>
          <Fragment>
            <div className="p-4 sm:hidden sm:w-[390px] sm:ml-36 bg-[#FFFFFF] rounded-lg flex items-center justify-center">
              <div className="py-4 space-y-4  ">
                <div
                  // key={idx}
                  className="flex justify-between bg-[#FFFFFF] h-44 flex-col w-100% border border-gray-300  px-2   rounded-lg md:flex-row items-start"
                >
                  <div className="flex flex-row space-x-4  p-4  ">
                    <div className="w-1/2 h-36">
                      <img
                        src={product?.avatar}
                        className="h-32 w-32 lg:h-36 rounded-md lg:w-36"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col space-y-1 w-1/2  ">
                      <div className="font-medium w-32 text-sm lg:text-base lg:w-52 ">
                        {product?.title}
                      </div>
                      {/* <div>
                          {product?.uuid}
                        </div>
                        <div>
                          {}
                        </div> */}
                      <div className="font-medium text-lg  ">
                        {product?.currency}{" "}
                        {_.sum([product?.price, product?.profit_added])}
                      </div>

                      <span>
                        {product?.combination_string?.split("&").join(", ")}
                      </span>

                      <div className="flex justify-between  w-full items-center flex-row lg:flex-row">
                        <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                          <button
                            onClick={() =>
                              setProductQty((prevQty) =>
                                prevQty > 1 ? prevQty - 1 : prevQty
                              )
                            }
                          >
                            <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                          <div>{productQty}</div>
                          <button
                            onClick={() =>
                              setProductQty((prevQty) => prevQty + 1)
                            }
                          >
                            <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Fragment>
          <Fragment>
            <div className="p-4 sm:pl-8 my-4 bg-[#FFFFFF] rounded-lg flex items-center justify-center">
              <div className="">
                <p className="font-medium">Checkout Items</p>
                <h4 className="text-sm font-light mb-2">
                  Fill your details below if you would like to make full payment
                  for this order.
                </h4>

                <form
                  //onSubmit={handleSubmit(onSubmit)}
                  className=" "
                >
                  <div className="mb-6">
                    <label
                      htmlFor="fullname"
                      className="block text-sm font-normal text-gray-500 "
                    >
                      Full name*
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm">
                      <TextInput
                        {...register("input.name", { required: true })}
                        type="text"
                        required
                        id="fullname"
                        className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% font-light  pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Name"
                      />
                      {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
                    </div>
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-normal text-gray-500 "
                    >
                      Phone number *
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm">
                      <TextInput
                        {...register("input.phone", { required: true })}
                        type="text"
                        required
                        id="phone"
                        className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                        placeholder=" 0202718168"
                      />
                      {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
                    </div>
                  </div>
                  <div className="mb-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-normal text-gray-500 "
                    >
                      Email
                    </label>
                    <div className="mt-2 relative rounded-md shadow-sm">
                      <TextInput
                        onChangeCapture={handleEmailInputChange}
                        value={emailValue}
                        {...register("input.email", { required: true })}
                        type="email"
                        required
                        id="email"
                        className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                        placeholder=" example@gmail.com"
                      />
                      {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
                    </div>
                    <div className="flex flex-row space-x-4 pt-6">
                      <div
                        className={clsx(
                          "w-4 h-4  mt-1 border-blue-300 border-2",
                          { "bg-blue-300": isFilled }
                        )}
                      >
                        <CheckIcon
                          onClick={checkbox}
                          className={clsx("text-white ")}
                        />
                      </div>
                      <div className="font-medium">Submit details?</div>
                    </div>

                    <div className=" font-light text-sm">
                      Cross-check your details for accuracy and submit.
                    </div>
                    {/* <button
            disabled={loading}
            type="submit"
            className="disabled:bg-gray-300 bg-pink-600 disabled:cursor-wait  w-64 lg:w-32 flex justify-center mx-auto lg:float-right mt-4 py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
          >
            {loading && <SwapSpinner size={45} color="white" />}
            {!loading && <p>Submit</p>}
          </button> */}
                  </div>
                </form>
              </div>
            </div>
          </Fragment>
          <Fragment>
            <div
              //  className=" p-4 lg:max-w-7xl  flex lg:flex-row   flex-col  lg:mx-auto lg:space-x-32"
              className=" sm:hidden flex lg:flex-row flex-col lg:space-x-32 "
            >
              <div className=" border-gray-300 bg-[#FFFFFF] p-3 px-4 w-full h-fit   rounded-lg space-y-3">
                <h2 className="font-bold text-xl">Order Summary </h2>
                <hr />

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-base">
                    <p className="font-light">Item(s)</p>
                    <p className="font-medium">{productQty}</p>
                  </div>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(
                        R.sum([product?.price!, product?.profit_added ?? 0])
                      ).format("#,#.##")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-base">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{lastItem?.currency} 100</p>
                    </div>
                  </Show>
                  {/* <div className="flex justify-between text-base">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(deliveryPrice).format("#,#.##") ?? "N/A"}
                    </p>
                  </div> */}

                  <div className="flex justify-between text-base">
                    <p className="font-light">TOTAL</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(productsTotal).format("#,#.##")}
                    </p>
                  </div>
                  <div className="my-11">
                    {/* <button onClick={createOrder}>Order</button> */}
                    {/* <div
                      ref={dukePaymentButtonRef}
                      style={{ display: "none" }}
                      //onClick={() => alert("Payment not Successful")}
                    > */}
                    <DukeDigitalOrderPaymentButton
                      // ref={dukePaymentButtonRef}
                      //  text={`Pay ${lastItem?.currency} ${total}`}
                      text={`Order`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={total}
                      orderId={""}
                      productId={id!} // Pass the product ID here
  productQty={productQty} // Pass the product quantity here
                      onCancel={() => alert("Payment not Succssful")}
                      onSuccess={() => alert("Success")}
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        <div className="sm:flex sm:flex-col mt-8 hidden sm:mt-0  ">
          <Fragment>
            <div className="p-4 sm:w-[390px] sm:ml-36 bg-[#FFFFFF] rounded-lg flex items-center justify-center">
              <div className="py-4 space-y-4  ">
                <div
                  // key={idx}
                  className="flex justify-between bg-[#FFFFFF] h-44 flex-col w-100% border border-gray-300  px-2   rounded-lg md:flex-row items-start"
                >
                  <div className="flex flex-row space-x-4  p-4  ">
                    <div className="w-1/2 h-36">
                      <img
                        src={product?.avatar}
                        className="h-32 w-32 lg:h-36 rounded-md lg:w-36"
                        alt=""
                      />
                    </div>
                    <div className="flex flex-col space-y-1 w-1/2  ">
                      <div className="font-medium w-32 text-sm lg:text-base lg:w-52 ">
                        {product?.title}
                      </div>
                      {/* <div>
                          {product?.uuid}
                        </div>
                        <div>
                          {}
                        </div> */}
                      <div className="font-medium text-lg  ">
                        {product?.currency}{" "}
                        {_.sum([product?.price, product?.profit_added])}
                      </div>

                      <span>
                        {product?.combination_string?.split("&").join(", ")}
                      </span>

                      <div className="flex justify-between  w-full items-center flex-row lg:flex-row">
                        <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                          <button
                            onClick={() =>
                              setProductQty((prevQty) =>
                                prevQty > 1 ? prevQty - 1 : prevQty
                              )
                            }
                          >
                            <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                          <div>{productQty}</div>
                          <button
                            onClick={() =>
                              setProductQty((prevQty) => prevQty + 1)
                            }
                          >
                            <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </Fragment>
          <Fragment>
            <div
              //  className=" p-4 lg:max-w-7xl  flex lg:flex-row   flex-col  lg:mx-auto lg:space-x-32"
              className={`p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32 ${
                pathname === "/cart/shippinginfo"
                  ? "p-4 lg:max-w-7xl flex lg:flex-row flex-col mt-20 lg:mx-auto lg:space-x-32"
                  : "p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32"
              }`}
            >
              <Helmet>
                <title>Cart</title>
              </Helmet>
              <div className="flex-1">
                <Outlet />
              </div>
              <div className=" border-gray-300 bg-[#FFFFFF] p-3 px-4  h-fit lg:w-96 w-full rounded-lg space-y-3">
                <h2 className="font-bold text-xl">Order Summary </h2>
                <hr />

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-base">
                    <p className="font-light">Item(s)</p>
                    <p className="font-medium">{productQty}</p>
                  </div>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(
                        R.sum([product?.price!, product?.profit_added ?? 0])
                      ).format("#,#.##")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-base">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{lastItem?.currency} 100</p>
                    </div>
                  </Show>
                  {/* <div className="flex justify-between text-base">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(deliveryPrice).format("#,#.##") ?? "N/A"}
                    </p>
                  </div> */}

                  <div className="flex justify-between text-base">
                    <p className="font-light">TOTAL</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(productsTotal).format("#,#.##")}
                    </p>
                  </div>
                  <div className="my-11">
                    {/* <button onClick={createOrder}>Order</button> */}
                    {/* <div
                      ref={dukePaymentButtonRef}
                      style={{ display: "none" }}
                      //onClick={() => alert("Payment not Successful")}
                    > */}
                    <DukeDigitalOrderPaymentButton
                      // ref={dukePaymentButtonRef}
                      //  text={`Pay ${lastItem?.currency} ${total}`}
                      text={`Order`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={total}
                      orderId=""
                      productId={id!} // Pass the product ID here
  productQty={productQty} // Pass the product quantity here
                      onCancel={() => alert("Payment not Succssful")}
                      onSuccess={() => alert("Success")}
                    />
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        </div>
      </div>
    </div>
  );
};

export default DigitalProductDeliveryPage;
