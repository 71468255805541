import clsx from "clsx";
import _ from "lodash";
import { Link, useNavigate } from "react-router-dom";
import { addToCart, removeFromCart } from "../../features/cart/cart-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";

type Props = {
  product: any;
};

const DigitalProductCard = ({ product }: Props) => {
  const { cartItems } = useAppSelector((state) => state.cart);
  const { shop } = useAppSelector((state) => state?.shop);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleCheckout = () => {
   // dispatch(addToCart(product));
    navigate(`/storefront-digital-order/${product?.id}`);
  };

  const alreadyAdded = !_.isEmpty(
    _.find(cartItems, (el) => el.id === product?.id)
  );

  function limitTitle(sentence: string, maxWidth: number) {
    if (sentence.length <= maxWidth) {
      return sentence;
    } else {
      return sentence.slice(0, maxWidth - 3) + "...";
    }
  }
  const originalSentence = product?.title;
  const maxWidth = 20;
  const limitedTitle = limitTitle(originalSentence, maxWidth);

  return (
    <div className="flex flex-col  lg:w-72  overflow-hidden rounded-lg  mb-8  bg-white">
      <div className="aspect-w-36  aspect-h-4  group-hover:opacity-75 sm:aspect-h-4 lg:h-72 sm:h-32">
        <Link
          to={{
            pathname: `/digital-product/${product?.id}`,
          }}
        >
          <img
            src={product?.avatar}
            alt={product?.title}
            className="h-52 lg:h-64 w-full sm:w-72 border-2  object-fit "
          />
        </Link>
      </div>
      {/* <div className="flex flex-1 flex-col space-y-2 p-4"> */}
      <div className="flex mt-2 sm:-mt-6 flex-1 space-y-1  ">
        <div className="flex flex-col space-y-1 mx-2 lg:mx-4">
          <div>
            <Link
              to={{
                pathname: `/digital-product/${product?.id}`,
              }}
            >
              <h3
                aria-hidden="true"
                className="text-sm font-medium   text-gray-900 "
              >
                {/* <button > */}
                {/* <span aria-hidden="true" className="absolute inset-0"></span> */}
                {limitedTitle}
                {/* </button> */}
              </h3>
            </Link>
          </div>

          <div>
            <p className=" font-bold  text-gray-900  text-xs">
              {product?.currency}{" "}
              {_.sum([product?.price, product?.profit_added])}
            </p>
          </div>
          {/* <div>
            ratings
          </div> */}

          <div>
            <button
              onClick={handleCheckout}
              className="bg-[#FFFFFF] border border-gray-400 rounded-md text-center text-sm text-black p-1 mb-3  w-40 sm:w-64"
            >
              Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalProductCard;
