import React from "react";
import SuccessPaymentComponent from "./SuccessPaymentComponent";

type Props = {};

const SuccessPaymentContainer = (props: Props) => {
  return <SuccessPaymentComponent />;
};

export default SuccessPaymentContainer;
