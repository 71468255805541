import { Carousel } from "antd";
import { useParams } from "react-router-dom";
import { useShopCollections } from "../../../hooks/useShopCollections";
import { useAppSelector } from "../../../app/hooks";
import image1 from "../../../assets/carousel.png";

import { Link } from "react-router-dom";
import Show from "../../../components/Show";

const Caro = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { id } = useParams();
  const { collections } = useShopCollections({
    filter: {
      shop_id: {
        eq: shop?.id,
      },
    },
  });

  return (
    <>
      <div className="bg-grayBg">
        <div className=" p-4">
          <section className="flex justify-between items-center gap-x-2"></section>

          {/* tabs section */}

          <Show if={collections?.length >= 1}>
            <section className="hidden md:block">
              <div className="mt-4 flex gap-x-4 items-center">
                <Link
                  to="/"
                  className={`${
                    id === undefined && "bg-lightGray"
                  }  py-2 px-4 rounded-md`}
                >
                  All
                </Link>
                {collections.map((collection, index) => (
                  <Link
                    to={`/collection/${collection?.id}`}
                    key={index}
                    className={`${
                      id === collection?.id && "bg-lightGray"
                    } text-md font-semibold text-gray-600 py-2 px-4 cursor-pointer rounded-md hover:bg-lightGray`}
                  >
                    {collection?.name}
                  </Link>
                ))}
              </div>
            </section>
          </Show>

          {/* carousel */}
          <Show if={collections?.length >= 1}>
            <section className="w-full">
              <div className="mt-4 w-full">
                <Carousel autoplay className="w-full">
                  {collections.map((collection, index) => (
                    <Link to={`/collection/${collection?.id}`}>
                      <img
                        key={index}
                        src={collection?.banner ? collection?.banner : image1}
                        alt="carousel"
                        className="w-full h-64 object-cover rounded-md"
                      />
                    </Link>
                  ))}
                </Carousel>
              </div>
            </section>
          </Show>
        </div>
      </div>
    </>
  );
};

export default Caro;
