import { useMutation } from "@apollo/client";
import { Tab } from "@headlessui/react";
import { CheckIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Tabs, clsx } from "@mantine/core";
import { message, notification } from "antd";
import _ from "lodash";
import moment from "moment";
import numeral from "numeral";
import * as R from "ramda";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import Show from "../../components/Show";
import TextInput from "../../components/TextInput/TextInput";
import { signinUser } from "../../features/auth/auth-slice";
import {
  addToCart,
  clearCart,
  decreaseQty,
  increaseQty,
  setCustomerDeliveryLocation,
} from "../../features/cart/cart-slice";
import {
  CustomerSignUpMutation,
  CustomerSignUpMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";
import useCart from "../../hooks/useCart";
import useDeliveryLocations, {
  usePickupLocations,
} from "../../hooks/useDeliveryLocations";
import { useProduct } from "../../hooks/useProduct";
import Notification from "../../notification/Notification";
type Props = {};

interface FormData {
  firstName: string;
  lastName: string;
}

const SingleCheckoutPage: React.FunctionComponent = () => {
  const [errorNotification, setErrorNotification] = useState({
    show: false,
    message: "",
  });
  const { shop } = useAppSelector((state) => state?.shop);
  let urlParams = new URLSearchParams(window.location.search);
  const data = urlParams.get(btoa("KEY").toLowerCase());
  if (!data) {
    // trigger error for invalid url
    alert(
      "Invalid URL, check and try again. Contact shop owner to resend the link"
    );
  }
  const encodedData = JSON.parse(atob(data as string)) as string[];
  const decodedData = encodedData.reverse().join("");
  const urlData = atob(decodedData);
  urlParams = new URLSearchParams(urlData);

  const total_selling_price = parseFloat(
    urlParams.get("total_selling_price") ?? ""
  );
  const inhouse = urlParams.get("inhouse");
  const verified = urlParams.get("verified");
  const uuid = urlParams.get("uuid");
  const id = urlParams.get("product_id");
  const shared_product_id = parseInt(urlParams.get("shared_product_id") ?? "");
  const title = urlParams.get("title");
  const sku = urlParams.get("sku");
  const price = parseFloat(urlParams.get("price") ?? "");
  const transit_stock = parseInt(urlParams.get("transit_stock") ?? "");
  const qty = parseInt(urlParams.get("qty") ?? "");
  const currency = urlParams.get("currency");
  const country = urlParams.get("country");
  const published = urlParams.get("published") === "true";
  const limited_stock = urlParams.get("limited_stock") === "true";
  const weight = parseFloat(urlParams.get("weight") ?? "");
  const avatar = decodeURIComponent(urlParams.get("avatar") ?? "");
  const shop_id = parseInt(urlParams.get("shop_id") ?? "");
  const supplier_id = urlParams.get("supplier_id");
  const shop_url = urlParams.get("shop_url");
  const profit_added = parseFloat(urlParams.get("profit_added") ?? "0");

  const extractedData = {
    total_selling_price,
    inhouse,
    verified,
    uuid,
    id,
    shared_product_id,
    title,
    sku,
    price,
    transit_stock,
    qty,
    currency,
    country,
    published,
    limited_stock,
    weight,
    avatar,
    shop_id,
    supplier_id,
    shop_url,
    profit_added,
  };

  const { product } = useProduct({
    filter: {
      id: {
        eq: extractedData?.id,
      },
      // shop: {
      //   shop_id: {
      //     eq: extractedData?.shop_id,
      //   },
      // },
    },
  });

  const { userInfo } = useAppSelector((state) => state.auth);
  const { cartLength, cartItems } = useCart();
  const lastItem = R.last(cartItems);

  const dispatch = useAppDispatch();
  const { register, handleSubmit, setValue } = useForm<
    CustomerSignUpMutationVariables,
    FormData
  >();

  const [address, setAddress] = useState("");
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });

  const handleSelect = async (value: string) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results[0]);
    console.log(ll);
    setAddress(value);
    setValue("input.address.area", value);
    setValue("input.address.city", city);

    setCoordinates(ll);
  };

  function extractCityFromAddress(address: any) {
    const addressParts = address.split(",");
    const city = addressParts[0].trim();
    return city;
  }
  const city = extractCityFromAddress(address);

  const [createAccount, { loading }] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = useCallback(
    (values) => {
      createAccount({
        variables: {
          ...values,
          userId: shop?.ownerId ?? "",
        },
        onCompleted: (res) => {
          // setShowSignIn(false);
          if (res?.customerSignUp) {
            console.log(res.customerSignUp);
            dispatch(signinUser(res?.customerSignUp as any));
            return;
          } else {
            notification.error({
              message: "please contact support",
            });
            return;
          }
        },
        onError: (err: { message: any }) => {
          notification.error({
            message: err?.message,
          });
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { customer_delivery_location } = useCart();
  const productsTotal = cartItems.reduce(
    (acc, product) => acc + R.sum([product.price ?? 0]) * product?.quantity,
    0
  );
  console.log(productsTotal);

  const total =
    numeral(
      cartItems?.reduce(
        (acc, curr) =>
          acc +
          R.multiply(
            curr?.quantity,
            R.sum([
              product?.price ?? extractedData?.price,
              extractedData?.profit_added ?? 0,
            ])
          ),
        0
      ) + (parseFloat(customer_delivery_location?.price) || 0)
    ).value() || 0;

  const [landmarkValue, setlandmark] = useState("");
  const [isFilled, setIsFilled] = useState(false);

  const handleEmailInputChange = (event: any) => {
    const value = event.target.value;
    // setEmailValue(value);
    setlandmark(value);

    if (value.trim() !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };

  const [activeTab, setActiveTab] = useState<string | null>("delivery");

  const [location_id, setlocation_id] = useState("");
  const { deliveryLocations } = useDeliveryLocations();
  const { pickupLocations } = usePickupLocations();

  const handleCloseNotification = () => {
    setErrorNotification({ show: false, message: "" });
  };
  const startDate = moment().add(4, "day").startOf("day");
  const endDate = moment().add(5, "day").startOf("day");
  const startDateArr = startDate.format("MMMM Do YYYY").split(" ");
  const endDateArr = endDate.format("MMMM Do YYYY").split(" ");
  useEffect(() => {
    if (location_id) {
      const item = _.find(deliveryLocations, (el) => el?.id === location_id);
      dispatch(setCustomerDeliveryLocation(item));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location_id, deliveryLocations]);

  useEffect(() => {
    if (extractedData && cartItems.length === 0) {
      dispatch(clearCart());
      dispatch(addToCart(extractedData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartItems.length, extractedData]);

  useEffect(() => {
    if (landmarkValue !== "") {
      handleSubmit(onSubmit)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landmarkValue]);

  const handleClick = useCallback(() => {
    setErrorNotification({
      show: true,
      message: "Kindly fill the form and select a delivery location.",
    });
  }, []);
  return (
    <div>
      <div className="flex flex-col-reverse md:flex-row  justify-between md:px-48 px-4 py-16">
        {/* shipping info */}
        <div className="flex flex-col">
          <h4 className="text-sm font-light pb-4">
            Fill your details below if you would like to make full payment for
            this order.
            <div></div>
          </h4>

          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-6">
              <label
                htmlFor="fullname"
                className="block text-sm font-medium  lg:text-black "
              >
                Full name <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.name", { required: true })}
                  type="text"
                  required
                  id="fullname"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Name"
                />
                {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="phone"
                className="block text-sm font-medium lg:text-black "
              >
                Phone number <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.phone", { required: true })}
                  type="text"
                  required
                  id="phone"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder=" 0202718168"
                />
                {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
              </div>
            </div>
            <div className="mb-6">
              <label
                htmlFor="email"
                className="block text-sm font-medium lg:text-black "
              >
                Email <span className="text-red-400">*</span>
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  // onChangeCapture={handleEmailInputChange}
                  // value={emailValue}
                  {...register("input.email", { required: true })}
                  type="email"
                  required
                  id="email"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder=" example@gmail.com"
                />
                {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
               */}
              </div>
              {/* <div className="mb-6 mt-6">
              <label
                htmlFor="city"
                className="block text-sm font-medium  lg:text-black "
              >
                City
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.address.city", { required: true })}
                  type="text"
                  required
                  id="city"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder="City"
                />
                 {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
              </div>
            </div> */}
              {/* <div className="mb-6">
              <label
                htmlFor="area"
                className="block text-sm font-medium  lg:text-black "
              >
                Area
              </label>
              <div className="mt-2 relative rounded-md shadow-sm">
                <TextInput
                  {...register("input.address.area", { required: true })}
                  type="text"
                  required
                  id="area"
                  className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                  placeholder="Area"
                />
               {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
              </div>
            </div> */}
              {/* google places code */}
              <div className="mb-6 mt-6">
                <label
                  htmlFor="Area"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Area <span className="text-red-400">*</span>
                </label>

                <PlacesAutocomplete
                  // {...register("input.address.area", { required: true })}
                  className="w-1/2"
                  value={address}
                  onChange={(value: string) => setAddress(value)}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }: {
                    getInputProps: any;
                    suggestions: any;
                    getSuggestionItemProps: any;
                    loading: any;
                  }) => (
                    <div>
                      <TextInput
                        {...register("input.address.area", { required: true })}
                        {...getInputProps({
                          placeholder: "Search Area ...",
                          className: "location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map(
                          (suggestion: {
                            active: any;
                            description:
                              | string
                              | number
                              | boolean
                              | React.ReactElement<
                                  any,
                                  string | React.JSXElementConstructor<any>
                                >
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                          }) => {
                            const className = suggestion.active
                              ? "suggestion-item--active"
                              : "suggestion-item";
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                }
                              : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>

              {/* google places code */}
              <div className="mb-6">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Address
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    // {...register("input.address.city", { required: true })}
                    type="text"
                    required
                    id="city"
                    value={city}
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Address"
                  />
                  {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="landmark"
                  className="block text-sm font-medium  lg:text-black "
                >
                  Landmark
                </label>
                <div className="mt-2 relative rounded-md shadow-sm">
                  <TextInput
                    {...register("input.address.landmark", { required: true })}
                    onChangeCapture={handleEmailInputChange}
                    type="text"
                    required
                    id="landmark"
                    className="focus:ring-sokoBlue focus:border-sokoBlue block w-80%   pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Landmark"
                  />
                  {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
                </div>
              </div>
              {!isFilled ? (
                <div>
                  <div className="flex flex-row space-x-4 pt-6">
                    <div className="w-4 h-4  mt-1 border-blue-400 border-2"></div>
                    <div className="font-medium">Create an account?</div>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row space-x-4 pt-6">
                  <div className="w-4 h-4 bg-blue-400 mt-1 border-blue-400 border-2">
                    <CheckIcon className="text-white " />
                  </div>
                  <div className="font-medium">Create an account?</div>
                </div>
              )}

              <div className=" font-light text-sm">
                Your details will be saved and used for other related shops.
              </div>
              {/* <button
            disabled={loading}
            type="submit"
            className="disabled:bg-gray-300 bg-amber-500 disabled:cursor-wait  flex justify-center float-right mt-4 py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
          >
            {loading && <SwapSpinner size={45} color="white" />}
            {!loading && <p>Submit</p>}
          </button> */}
            </div>
          </form>

          {/* </div> */}
          {/* shipping info */}

          {/* Delivery Page */}
          <div>
            <Tabs value={activeTab} onTabChange={setActiveTab}>
              {/* <Tabs.List>
                <Tabs.Tab value="delivery">Delivery</Tabs.Tab>
                <Tabs.Tab disabled value="pickup">
                  Pickup
                </Tabs.Tab>
              </Tabs.List> */}

              <Tabs.Panel value="delivery">
                <div className="flex flex-col my-4 w-96">
                  <select
                    name=""
                    id="select"
                    onChange={(e) => setlocation_id(e.target.value)}
                  >
                    <option value="">Select Delivery Zone (required)</option>
                    {deliveryLocations.map((location, idx) => (
                      <option
                        key={idx}
                        value={location?.id}
                        className="flex w-64 whitespace-nowrap overflow-hidden text-ellipsis"
                      >
                        {location?.location} - {location?.price}
                      </option>
                    ))}
                  </select>
                </div>
              </Tabs.Panel>
            </Tabs>

            <div className="bg-gray-400 p-3 rounded-md flex flex-col my-4">
              <span className="text-white">
                {customer_delivery_location?.location}
              </span>
              <span className="text-white">
                Price : {customer_delivery_location?.price}
              </span>
              {/* <span className="text-white">
                Remark:{" "}
                {customer_delivery_location?.isPayOnline
                  ? "Pay Online Only"
                  : "Payment On Delivery allowed"}
              </span> */}
            </div>
          </div>
          <Fragment>
            <div className=" flex lg:hidden  flex-col  lg:mx-auto lg:space-x-32">
              {/* <div className="flex-1">
          <Outlet />
        </div> */}
              <div className="   px-4 p-3  h-fit lg:w-96 w-full rounded-sm space-y-3">
                <h2 className="font-medium text-xl">Order Summary </h2>

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-base">
                    {/* <p className="font-light">{plural("Item", cartLength, true)}</p> */}
                    <p className="font-medium"></p>
                  </div>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(
                        cartItems?.reduce(
                          (acc, curr) =>
                            acc +
                            R.multiply(
                              curr?.quantity,
                              R.sum([
                                extractedData?.price,
                                extractedData?.profit_added ?? 0,
                              ])
                            ),
                          0
                        )
                      ).format("#,#")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-base">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{lastItem?.currency} 100</p>
                    </div>
                  </Show>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(customer_delivery_location?.price).format(
                        "#,#"
                      ) ?? "N/A"}
                    </p>
                  </div>
                  {/* <hr /> */}
                  <div className="flex justify-between text-base">
                    <p className="font-light">TOTAL</p>
                    <p className="font-medium">
                      {lastItem?.currency} {numeral(total).format("#,#")}
                    </p>
                  </div>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Estimated Delivery by</p>
                    <p className="font-medium">
                      {startDateArr[0] + " " + startDateArr[1]} and{" "}
                      {endDateArr[0] + " " + endDateArr[1]}
                    </p>
                  </div>
                </div>
                <div className="my-11">
                  {errorNotification.show && (
                    <Notification
                      type="error"
                      message={errorNotification.message}
                      onClose={handleCloseNotification}
                    />
                  )}
                  {!isFilled ? (
                    <div
                      onClick={handleClick}
                      className="bg-gray-400 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-gray-500  w-full flex-1 text-center text-"
                    >
                      {" "}
                      {`Pay ${extractedData?.currency} ${total}`}
                    </div>
                  ) : (
                    <DukePaymentButton
                      text={`Pay ${extractedData?.currency} ${total}`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={total}
                      orderId=""
                      onCancel={() => alert("Payment not Succssful")}
                      onSuccess={() => alert("Success")}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </div>
        {/* Delivery Page */}
        <div className="flex flex-col">
          {/* Cart items Preview */}
          <div className="py-4 mb-6 md:pl-24 space-y-4 lg:ml-12">
            {cartItems?.map((item, idx) => (
              <div
                key={idx}
                className="flex justify-between flex-col md:flex-col items-start"
              >
                <div className="flex  space-x-6">
                  <div className="w-[160px] h-[160px] md:hidden">
                    <img
                      src={product?.avatar ?? extractedData?.avatar}
                      className="h-full w-full"
                      alt=""
                    />
                  </div>
                  <Tab.Group
                    as="div"
                    className="flex flex-col-reverse -mr-16  "
                  >
                    <div className="mx-auto mt-6 hidden w-full max-w-2xl  sm:block lg:max-w-none">
                      <Tab.List className="grid grid-cols-4 gap-6">
                        {product?.media?.map((image: any) => (
                          <Tab
                            key={image?.id}
                            className="relative flex h-12 w-12 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                          >
                            {({ selected }) => (
                              <>
                                <span className="absolute inset-0 overflow-hidden rounded-md">
                                  <img
                                    src={image?.avatar ?? ""}
                                    alt=""
                                    className="h-full w-full object-cover object-center"
                                    // onClick={() => handleImageClick(image?.avatar)}
                                  />
                                </span>
                                <span
                                  className={clsx(
                                    selected
                                      ? "ring-pink-600"
                                      : "ring-transparent",
                                    "pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2"
                                  )}
                                  aria-hidden="true"
                                />
                              </>
                            )}
                          </Tab>
                        ))}
                      </Tab.List>
                    </div>

                    <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
                      {product?.media?.map((image: any) => (
                        <Tab.Panel key={image?.id}>
                          <img
                            src={extractedData?.avatar}
                            alt={extractedData?.title!}
                            className="h-full w-full object-cover object-center sm:rounded-lg"
                          />
                        </Tab.Panel>
                      ))}
                    </Tab.Panels>
                  </Tab.Group>
                  <div className="flex flex-col  items-start space-y-2  lg:space-y-8 md:pl-6 ">
                    <span className="pb-1 font-medium">
                      {extractedData?.title}
                    </span>
                    <span className="font-medium">
                      {extractedData?.currency}{" "}
                      {_.sum([
                        extractedData?.price,
                        extractedData?.profit_added,
                      ])}
                    </span>
                    <span>
                      {item?.combination_string?.split("&").join(", ")}
                    </span>
                    {/* <div>{product?.}</div> */}
                    {/* {extractedData?.limited_stock==true ? (<div>Limited stock</div>):(<div></div>)} */}

                    <div className="w-48">
                      <span className="font-medium">{item?.qty}</span> Pieces
                      available in stock
                    </div>
                    <div className="flex space-x-2 items-center flex-col lg:flex-row ">
                      <div className="border flex justify-between w-36 border-gray-400 p-1 items-center">
                        <button onClick={() => dispatch(decreaseQty(idx))}>
                          <MinusIcon className="h-6 w-6 hover:cursor-pointer" />
                        </button>
                        <div>{item?.quantity}</div>

                        <button onClick={() => dispatch(increaseQty(idx))}>
                          <PlusIcon className="h-6 w-6" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* Cart items preview */}

          {/* cart gallery */}

          {/* cart gallery */}

          {/* Order Summary */}

          <Fragment>
            <div className=" hidden lg:flex lg:max-w-7xl   lg:flex-row    flex-col  lg:mx-auto lg:space-x-32">
              {/* <div className="flex-1">
          <Outlet />
        </div> */}
              <div className="   px-4 p-3  h-fit lg:w-96 w-full rounded-sm space-y-3">
                <h2 className="font-medium text-xl">Order Summary </h2>

                <div className="space-y-4 w-full mb-11">
                  <div className="flex justify-between text-sm">
                    {/* <p className="font-light">{plural("Item", cartLength, true)}</p> */}
                    <p className="font-medium"></p>
                  </div>
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Subtotal</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(
                        cartItems?.reduce(
                          (acc, curr) =>
                            acc +
                            R.multiply(
                              curr?.quantity,
                              R.sum([
                                extractedData?.price,
                                extractedData?.profit_added ?? 0,
                              ])
                            ),
                          0
                        )
                      ).format("#,#")}
                    </p>
                  </div>
                  <Show if={false}>
                    <div className="flex justify-between text-sm">
                      <p className="font-light">Discount</p>
                      <p className="font-medium">{lastItem?.currency} 100</p>
                    </div>
                  </Show>
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Delivery</p>
                    <p className="font-medium">
                      {extractedData?.currency}{" "}
                      {numeral(customer_delivery_location?.price).format(
                        "#,#"
                      ) ?? "N/A"}
                    </p>
                  </div>
                  {/* <hr /> */}
                  <div className="flex justify-between text-sm">
                    <p className="font-light">Total</p>
                    <p className="font-medium">
                      {lastItem?.currency}{" "}
                      {numeral(
                        cartItems?.reduce(
                          (acc, curr) =>
                            acc +
                            R.multiply(
                              curr?.quantity,
                              R.sum([
                                extractedData?.price ?? 0,
                                extractedData?.profit_added ?? 0,
                              ])
                            ),
                          0
                        ) + (parseFloat(customer_delivery_location?.price) || 0)
                      ).format("#,#")}
                    </p>
                  </div>
                  <div className="flex flex-row justify-between text-sm">
                    <div className="font-light w-20 ">
                      Estimated Delivery by
                    </div>
                    <p className="font-medium">
                      {startDateArr[0] + " " + startDateArr[1]} and{" "}
                      {endDateArr[0] + " " + endDateArr[1]}
                    </p>
                  </div>
                </div>
                <div className="">
                  {errorNotification.show && (
                    <Notification
                      type="error"
                      message="Kindly fill the form and select a delivery location."
                      onClose={handleCloseNotification}
                    />
                  )}
                  {!isFilled ? (
                    <div
                      onClick={handleClick}
                      className="bg-gray-400 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-gray-500  w-full flex-1 text-center text-"
                    >
                      {" "}
                      {`Pay ${extractedData?.currency} ${total}`}
                    </div>
                  ) : (
                    <DukePaymentButton
                      text={`Pay ${extractedData?.currency} ${total}`}
                      className="bg-pink-600 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                      billingEmail={userInfo?.email || ""}
                      firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                      lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                      phone={userInfo?.phone ?? ""}
                      amount={total}
                      orderId=""
                      onCancel={() => alert("Payment not Successful")}
                      onSuccess={() => alert("Success")}
                    />
                  )}
                </div>
              </div>
            </div>
          </Fragment>

          {/* Order Summary */}
        </div>
      </div>
    </div>
  );
};

export default SingleCheckoutPage;
