import React from "react";
import CheckoutComponent from "./CheckoutComponent";

type Props = {};

const CheckoutContainer = (props: Props) => {
  return <CheckoutComponent />;
};

export default CheckoutContainer;
