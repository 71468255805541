import { Fragment } from "react";
import useCart from "../../hooks/useCart";
import plural from "pluralize";
import * as R from "ramda";
import Show from "../../components/Show";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import _ from "lodash";
import numeral from "numeral";
import moment from "moment";
import { Helmet } from 'react-helmet-async';

import { useAppSelector } from "../../app/hooks";

const Cart = () => {
  const navigate = useNavigate();
  const { cartLength, cartItems, customer_delivery_location,deliveryPrice } = useCart();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();

  const lastItem = R.last(cartItems);
  // const { deliveryLocations } = useDeliveryLocations({
  //   filter: {
  //     id: {
  //       eq: userInfo?.id,
  //     },
  //   },
  // });
  // const [tempId, setTempID] = useState<string>("");

  const productsTotal = cartItems.reduce(
    (acc, product) =>
      acc +
      R.sum([product.price, product?.profit_added ?? 0]) * product?.quantity,
    0
  );

  const total = R.sum([
    productsTotal,
    parseFloat(deliveryPrice as unknown as string) || 0,
  ]);

//   const startDate =moment().add(4, "day").calendar();
//   const startDateArr=startDate.split(" ");
// const endDate = moment().add(5, "day").calendar();
// const endDateArr=endDate.split(" ");
// const startDate = moment().add(4, "day").startOf('day');
// const endDate = moment().add(5, "day").startOf('day');
//  const startDateArr = startDate.format('MMMM Do YYYY').split(" ")
//  const endDateArr = endDate.format('MMMM Do YYYY').split(" ")

  return (
    <Fragment>
      <div
      //  className=" p-4 lg:max-w-7xl  flex lg:flex-row   flex-col  lg:mx-auto lg:space-x-32"
      className={`p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32 ${
        pathname === "/cart/shippinginfo" ? 'p-4 lg:max-w-7xl flex lg:flex-row flex-col mt-20 lg:mx-auto lg:space-x-32' : 'p-4 lg:max-w-7xl flex lg:flex-row flex-col lg:mx-auto lg:space-x-32'
      }`}
       >
      <Helmet>
        <title>Cart</title>
        
      </Helmet>
        <div className="flex-1">
          <Outlet />
        </div>
        <div className=" border-gray-300 p-3 px-4 md:mt-16 h-fit lg:w-96 w-full rounded-sm space-y-3">
          <h2 className="font-bold text-xl">Order Summary </h2>
          <hr />

          <div className="space-y-4 w-full mb-11">
            <div className="flex justify-between text-base">
              <p className="font-light">Item(s)</p>
              <p className="font-medium">{ cartLength}</p>
            </div>
            <div className="flex justify-between text-base">
              <p className="font-light">Subtotal</p>
              <p className="font-medium">
                {lastItem?.currency}{" "}
                {numeral(
                  cartItems?.reduce(
                    (acc, curr) =>
                      acc +
                      R.multiply(
                        curr?.quantity,
                        R.sum([curr?.price, curr?.profit_added ?? 0])
                      ),
                    0
                  )
                ).format("#,#.##")}
              </p>
            </div>
            <Show if={false}>
              <div className="flex justify-between text-base">
                <p className="font-light">Discount</p>
                <p className="font-medium">{lastItem?.currency} 100</p>
              </div>
            </Show>
            <div className="flex justify-between text-base">
              <p className="font-light">Delivery</p>
              <p className="font-medium">
                {lastItem?.currency}{" "}
                {numeral(deliveryPrice).format("#,#.##") ??
                  "N/A"}
              </p>
            </div>
            
            <div className="flex justify-between text-base">
              <p className="font-light">TOTAL</p>
              <p className="font-medium">
                {lastItem?.currency} {numeral(total).format("#,#.##")}
              </p>
            </div>
            <div className="my-11">
            {pathname === "/cart/delivery" ? (
              <DukePaymentButton
                text={`Pay ${lastItem?.currency} ${total}`}
                className="bg-pink-600 p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white  w-full flex-1"
                billingEmail={userInfo?.email || ""}
                firstName={_.first(userInfo?.name.split(" ")) ?? ""}
                lastName={_.last(userInfo?.name.split(" ")) ?? ""}
                phone={userInfo?.phone ?? ""}
                amount={total}
                orderId=""
                onCancel={() => alert("Payment not Succssful")}
                onSuccess={() => alert("Success")}
              />
              ):pathname==="/cart/shippinginfo"?(

              <button
              className="hidden md:hidden bg-white"
              >

              </button>
            ):(
              <button
                onClick={() => navigate(_.get(nextPages, pathname))}
                className="bg-pink-600 p-3 hover:bg-white hover:border hover:border-black hover:text-black text-white rounded-full w-full flex-1"
              >
                {_.get(nextPagesLabel, pathname)}
              </button>
            )
            }
          </div>
            {/* <div className="flex justify-between text-base">
              <p className="font-light">Estimated Delivery between</p>
              <p className="font-medium">
                {startDateArr[0] + " " + startDateArr[1]} and{" "}
                {endDateArr[0] + " " + endDateArr[1]}
              </p>
            </div> */}
          </div>
          
        </div>
      </div>
    </Fragment>
  );
};

export default Cart;

const nextPages = {
  "/cart":"/cart/shippinginfo",
  "/cart/shippinginfo": "/cart/address",
  "/cart/address": "/cart/delivery",
  "/cart/delivery": "/cart/final",
};

const nextPagesLabel = {
  "/cart": "Proceed to Checkout",
  
  "/cart/address": "Proceed to Delivery",
  "/cart/delivery": "Proceed to Payment",
  "/cart/final": "Place Order",
};
