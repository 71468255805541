import { ArrowRightIcon,CheckIcon } from "@heroicons/react/24/outline";
import TextInput from "../../components/TextInput/TextInput";
import { CustomerSignUpMutationVariables,CustomerSignUpMutation } from "../../graphql/mutations/__generated__/mutations";
import { signinUser } from "../../features/auth/auth-slice";
import { useMutation } from "@apollo/client";
import { Link, useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useAppDispatch,useAppSelector } from "../../app/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { CUSTOMER_SIGNUP } from "../../graphql/mutations/mutations";
import { SwapSpinner } from "react-spinners-kit";
import { useState } from "react";
const ShippingInfo = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    
  } = useForm<CustomerSignUpMutationVariables>();
  const [createAccount, { loading }] = useMutation<
    CustomerSignUpMutation,
    CustomerSignUpMutationVariables
  >(CUSTOMER_SIGNUP);
  const onSubmit: SubmitHandler<CustomerSignUpMutationVariables> = (values) => {
    createAccount({
      variables: {
        ...values,
        userId: shop?.ownerId ?? "",
      },
      onCompleted: (res) => {
        // setShowSignIn(false);
        if (res?.customerSignUp) {
          console.log(res.customerSignUp);
          dispatch(signinUser(res?.customerSignUp as any));
          // setShow(false);
          // setShowSignIn(false);
          //   setLogin(true);
          navigate("/cart/address");

          return;
        } else {
          notification.error({
            message: "please contact support",
          });
          return;
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };

  const [emailValue, setEmailValue] = useState('');
  const [isFilled, setIsFilled] = useState(false);

  const handleEmailInputChange = (event: any) => {
    const value = event.target.value;
    setEmailValue(value);

    if (value.trim() !== '') {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  };
  return (
    <div className="space-y-4 w-full ">
      <div className="flex items-center lg:w-full lg:top-32 absolute top-24 w-48 space-x-2 mb-11">
        <Link to="/cart">
        <h3 className="small-caps font-extralight text-xl text-gray-300 hover:text-orange-500">
          Cart
        </h3>
        </Link>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h3 className=" font-bold text-xl">Contact Info</h3>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h3 className="font-extralight text-xl text-gray-300">Shipping</h3>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h1 className="small-caps font-extralight text-xl text-gray-300 ">
          Delivery
        </h1>
        <ArrowRightIcon className="text-gray-300 h-6" />
        <h3 className="small-caps font-extralight text-xl text-gray-300">
          Payment
        </h3>
      </div>

      <div className="">
      <h4 className="text-sm font-light">
        Fill your details below if you would like to make full payment for this order.
      </h4>

      <form onSubmit={handleSubmit(onSubmit)} className=" ">
        <div className="mb-6">
          <label
            htmlFor="fullname"
            className="block text-sm font-normal text-gray-500 "
          >
            Full name*
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
                {...register("input.name", { required: true })}
              type="text"
              required
              id="fullname"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% font-light  pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder="Name"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )} */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="phone"
            className="block text-sm font-normal text-gray-500 "
          >
            Phone number *
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
                {...register("input.phone", { required: true })}
              type="text"
              required
              id="phone"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder=" 0202718168"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
        </div>
        <div className="mb-6">
          <label
            htmlFor="email"
            className="block text-sm font-normal text-gray-500 "
          >
            Email
          </label>
          <div className="mt-2 relative rounded-md shadow-sm">
            <TextInput
            onChangeCapture={handleEmailInputChange}
            value={emailValue}
                {...register("input.email", { required: true })}
              type="email"
              required
              id="email"
              className="focus:ring-sokoBlue focus:border-sokoBlue block w-80% pl-4 pr-12 py-3 sm:text-sm border-gray-300 rounded-md"
              placeholder=" example@gmail.com"
            />
            {/* {errors?.input?.phone && (
                  <span className="text-red-600">required</span>
                )}
            */}
          </div>
          {!isFilled?(<div>
            <div className="flex flex-row space-x-4 pt-6">
            <div className="w-4 h-4  mt-1 border-blue-300 border-2"></div>
            <div className="font-medium">Create an account?</div>
          </div>
          </div>):(
            <div className="flex flex-row space-x-4 pt-6">
            <div className="w-4 h-4 bg-blue-300 mt-1 border-blue-300 border-2">
              <CheckIcon className="text-white "/>
            </div>
            <div className="font-medium">Create an account?</div>
          </div>
          )}
          
          <div className=" font-light text-sm">Your details will be saved and used for other related shops.</div>
          <button
            disabled={loading}
            type="submit"
            className="disabled:bg-gray-300 bg-pink-600 disabled:cursor-wait  w-64 lg:w-32 flex justify-center mx-auto lg:float-right mt-4 py-3 px-4 border border-transparent rounded-full shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-600"
          >
            {loading && <SwapSpinner size={45} color="white" />}
            {!loading && <p>Submit</p>}
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default ShippingInfo;
