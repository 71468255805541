import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../app/hooks";
import Collections from "../../layouts/StoreFront/components/Collections";
import errorImage from "./404-error.png"
import Products from "../../layouts/StoreFront/components/Products";
import { Helmet } from 'react-helmet-async';





 
type Props = {
};

const HomePage = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
 
  
  return (
    
    <main>

      <Helmet>
        <title>{shop?.name + "'s shop - Online Storefront  "}</title>
        <meta property='description' content={"Welcome to my shop | "+shop!.name  }/>
{/*         
        <meta property="og:image" itemProp="image"  content="https://d3ug0vbiixnxyq.cloudfront.net/logos/40ctl72xehiurg0pyzukl7-168503038246.jpeg"/> */}
        <meta property="og:type" content="website"/>
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="300"/>
        <meta property="og:image:height" content="300"/>
      </Helmet>
      <Collections />
      {/* Product listing section */}
      <Products />
    </main>
    
    
  );

  
  
};

export default HomePage;
