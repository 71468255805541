/* This example requires Tailwind CSS v2.0+ */
import React, {
    Children,
    Fragment,
    ReactNode,
    SetStateAction,
    useRef,
  } from "react";
  import { Dialog, Transition } from "@headlessui/react";
  import { XMarkIcon } from "@heroicons/react/24/outline";
  
  type ModalProps = {
    show: boolean;
    setShow: React.Dispatch<SetStateAction<boolean>>;
    children: ReactNode | ReactNode[];
    title?: string;
    showCloseIcon?: boolean;
    
  };
  
  export default function Modal({
    show,
    setShow,
    children,
    title,
    showCloseIcon,
    
  }: ModalProps) {
    //   const [open, setOpen] = useState(true)
  
    const allChildren = Children.map(children, (child) => {
      if (child) {
        if (typeof child !== "function") return child;
        const clone = React.cloneElement(child, {
          setShow: setShow,
        });
  
        return clone;
      }
      return null;
    });
  
    const cancelButtonRef = useRef(null);
  
    const closeModal = () => {
      setShow(false);
    };
  
    return (
      <Transition.Root show={show} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          onClose={setShow}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
  
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom py-6 bg-white rounded text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <div className=" px-6 flex justify-between">
                  <span className="font-semibold text-lg ">{title}</span>
                  {showCloseIcon && (
                    <XMarkIcon
                      onClick={closeModal}
                      className="h-6 w-6 cursor-pointer text-gray-600"
                    />
                  )}
                </div>
                <div className="bg-white  pt-3 pb-4 px-6 sm:pb-4 rounded-md">
                  {allChildren}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  }
  