import useFetchOrders from "../../hooks/useFetchOrders";
import useOrderCart from "../../hooks/useOrderCart";
import { useAppSelector } from "../../app/hooks";
import Order from "./Order";
import { Helmet } from "react-helmet-async";
import SearchOrder from "./SearchOrder";
const TrackOrder = () => {
    const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
  console.log(userInfo);
  const { orders, loading } = useFetchOrders({
    filter: {
      customer_id: {
        eq: userInfo?.id,
      },
    },
  });
  const {cartorders}=useOrderCart({
    filter:{
      customer_id:{
        eq : userInfo?.id 
      }
    }
  });

  const handleSearch = (searchTerm: any) => {
    // Perform search logic here using the searchTerm
    console.log('Searching for:', searchTerm);
  };
    return ( 
        <div className=" w-full ">
          <Helmet>
          <title>Track my orders</title>
          <meta property='og:title' content="Track my orders"/>
          </Helmet>
        <div className=" bg-white   ">
        
            <div>
          {/* <h1 className="text-xl font-normal sm:px-4 md:ml-16 my-6">Input your tracking order number below </h1> */}
        </div>
        <div>
        {/* <Order orders={cartorders} /> */}
        <SearchOrder/>
        </div>
        </div>
        </div>
     );
}
 
export default TrackOrder;