import React, { forwardRef, Children } from "react";
import clsx from "clsx";
import Spinner from "../spinners/spinner";


type ButtonProps = {
  children: React.ReactNode | React.ReactNode[];
  variant?: "primary" | "secondary" | "tertiary";
  size?: "small" | "medium" | "large";
  loading?: boolean;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, variant, loading, size = "medium", ...props }, ref) => {
    const isPrimary = variant === "primary";
    const isSecondary = variant === "secondary";
    const isTertiary = variant === "tertiary";

    // const handleClick = (
    //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    // ) => {
    //   if (!loading && props.onClick) {
    //     props.onClick(e);
    //   }
    // };

    return (
      <button
        {...props}
        ref={ref}
        type="button"
        className={clsx(
          "inline-flex items-center justify-center border rounded-md  px-4 py-3 text-sm font-medium  shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2 sm:w-auto",
          {
            "bg-teal-600 hover:bg-teal-700 border-transparent text-white focus:ring-teal-500":
              isPrimary,
            "bg-amber-600 hover:bg-amber-700 border-transparent text-white  focus:ring-amber-500":
              isSecondary,
            "bg-transparent hover:bg-gray-100 focus:ring-gray-500 text-gray-600 border-gray-400 ":
              isTertiary,
          }
        )}
      >
        {loading ? (
          <Spinner size={size} variant={"secondary"} />
        ) : (
          Children.map(children, (child, i) => {
            return (
              <span key={i} className="mr-xsmall last:mr-0">
                {child}
              </span>
            );
          })
        )}
      </button>
    );
  }
);

export default Button;
