import { useEffect, useRef, useState } from "react";
import Modal from "../Modal/Modal";
import React from "react";
import { VERIFY_OTP } from "../../graphql/mutations/mutations";
import { VerifyOtpMutation,VerifyOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { notification } from "antd";
import { signinUser } from "../../features/auth/auth-slice";
import { useAppDispatch } from "../../app/hooks";
import { CUSTOMER_SIGNIN } from "../../graphql/mutations/mutations";
import {
  CustomerSignInMutation,
  CustomerSignInMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { REQUEST_OTP } from "../../graphql/mutations/mutations";
import { RequestOtpMutation,RequestOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";

interface Props {
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    phonedata : string;
    token:string;
    setShowSignIn:React.Dispatch<React.SetStateAction<boolean>>;
  }

  let currentOTPIndex: number =0;
const VerifyOtpModal = ({ setShow ,phonedata,token, setShowSignIn}: Props) => {
    const dispatch = useAppDispatch();
    const [newtoken, settoken]= useState<string>(token);
    const [otp,setOtp]=useState<string[]>(new Array(4).fill(""));
    const OTP = otp.join('');
    const [activeOTPIndex,setActiveOTPIndex]=useState<number>(0);
    const inputRef = useRef<HTMLInputElement>(null);
    const handleOnChange=({target,}:React.ChangeEvent<HTMLInputElement>):void=>{
        const {value}=target;
        const newOTP:string[] =[...otp]
        newOTP[currentOTPIndex]=value.substring(value.length-1);
        if(!value)setActiveOTPIndex(currentOTPIndex-1)
        else setActiveOTPIndex(currentOTPIndex + 1);
        
        setOtp(newOTP);
       
    };

    const handleOnKeyDown=({key}:React.KeyboardEvent<HTMLInputElement>,index:number)=>{
        currentOTPIndex=index
        if(key==='Backspace') setActiveOTPIndex(currentOTPIndex-1)
    }

    useEffect(()=>{
        inputRef.current?.focus()
    },[activeOTPIndex])

    const [RequestOtp] = useMutation<
    RequestOtpMutation,
    RequestOtpMutationVariables
  >(REQUEST_OTP);

  const GetNewOtp=()=>{
    RequestOtp({
        variables: {
          input:{
            phone:phonedata,
            
          }
        },
        
        onCompleted: (res) => {
          if (res?.requestOTP) {
            // setVerifyOtp(true);
            // setphone(values?.input?.phone!);
            settoken(res?.requestOTP.token!);
            
             notification.success({
                message:"new otp has been sent"
             })
            return;
          } else {
            notification.error({
              message: "please contact support",
            });
            return;
          }
        },
        onError: (err) => {
          notification.error({
            message: err?.message,
          });
        },
      });
  }

    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm<VerifyOtpMutationVariables>();
    
      const [VerifyOtp, { loading }] = useMutation<
        VerifyOtpMutation,
        VerifyOtpMutationVariables
      >(VERIFY_OTP);
      const [signIn] = useMutation<
     CustomerSignInMutation,
    CustomerSignInMutationVariables
   >(CUSTOMER_SIGNIN);

  const login=()=>{
    signIn({
    variables: {
      input:{
        phone:phonedata,

      }
     },
     onCompleted: (res) => {
             if (res?.customerSignIn) {
              console.log(res?.customerSignIn,"customerdata")
                dispatch(signinUser(res?.customerSignIn));
                
            //  setShow(false);
        
                 return;
             } else {
               notification.error({
                  message: "please contact support",
                });
                return;
              }
            },
            onError: (err) => {
              notification.error({
                 message: err?.message,
              });
             },
    }) 
}   
      const onSubmit: SubmitHandler<VerifyOtpMutationVariables> = (values) => {
        console.log(values);
    
        VerifyOtp({
          variables: {
            // ...values,
            input:{
                medium:"phone",
                code:OTP,
                token:newtoken,
                update:true
            }
          },
          
          onCompleted: (res) => {
            if (res?.verifyOTP) {
            //   dispatch(signinUser());
            login();
              setShow(false);
              setShowSignIn(false);
              return;
            } else {

              notification.error({
                message: "please contact support",
              });
              return;
            }
          },
          onError: (err) => {
            console.log(err);
            notification.error({
              message: err?.message,
            });
          },
        });
      };

      
    

    return ( 
        <div>
            
            <div className="flex flex-col justify-between  space-y-2 mb-11  ">
            <h4 className="text-3xl text-tendo-200 text-center my-2">Verify OTP</h4>
            <p className="text-sm text-center font-light">An OTP has been sent to your mobile number and email, please verify it below</p>
            <div className="flex justify-center items-center space-x-2 pt-4">
                {otp.map((_,index)=>{
                    return(
                        <React.Fragment key={index}>
                            
                            <input
                            ref={index===activeOTPIndex ? inputRef :null}
                             type="number"
                            className="w-12 h-12 border rounded bg-transparent outline-none
                            text-center font-normal text-xl transition [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                            onChange={handleOnChange}
                            onKeyDown={(e)=>handleOnKeyDown(e,index)}
                            value={otp[index]}
                            />
                           
                        </React.Fragment>
                    )
                })}
            </div>
            <p className="text-sm text-center font-light pb-8 pt-4">Didn't receive OTP ? 
            <span
            onClick={GetNewOtp}
             className="font-bold text-blue-500"> RESEND OTP</span>
            </p>
            <button
            // variant="primary"
           onClick={handleSubmit(onSubmit)}
            // loading={loading}
            //  type="submit"
            
            className="disabled:bg-gray-300 disabled:cursor-wait flex w-64 mx-auto  justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Verify 
          </button>
       
            </div>
           
        </div>
     );
}
 
export default VerifyOtpModal;

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
