import { MinusIcon, PlusIcon, TrashIcon,StarIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import _ from "lodash";
import plural from "pluralize";
import React, { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import useDigitalOrder from "../../hooks/useDigitalOrder";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import useCart from "../../hooks/useCart";
import { useLocation, Outlet, useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";
import numeral from "numeral";
import { RequestOtpMutation,RequestOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { ChannelType } from "../../graphql/types";
import { REQUEST_OTP } from "../../graphql/mutations/mutations";
import { useProduct } from "../../hooks/useProduct";
import { useMutation } from "@apollo/client";
const StorefrontOrderPage = () => {
  const { id } = useParams();
  let urlParams = new URLSearchParams(window.location.search);
  const invoice = urlParams.get("invoice");
  const { digitalorder } = useDigitalOrder({
    filter: {
      id: {
        eq: invoice,
      },
    },
  });
  const [requestOTP, { loading: requestingOTP }] = useMutation<
  RequestOtpMutation,
  RequestOtpMutationVariables
  >(REQUEST_OTP);
    const request = ()=>{
      requestOTP({
        variables:{
          input:{
            phone:userInfo?.phone,
           // email:userInfo?.email,
            channel:"sms" as ChannelType 
          }
  
        }})
        .then((res)=>{
          if (res.data?.requestOTP) {
            navigate(`/input-otp/${res.data?.requestOTP?.token}/${id}`);
          }
          console.log(res);
        })
        .catch((err)=>{
          console.log(err);
        })
    }
  const { cartLength, cartItems, deliveryPrice } = useCart();
  const navigate = useNavigate();

  console.log(digitalorder?.productId)
  const { shop } = useAppSelector((state) => state?.shop);
  const { product, refetch } = useProduct({
    "filter": {
      "id": {
        "eq": id,
      },
    
    },
  });
  
  const [productQty, setProductQty] = useState(1);
  const { pathname } = useLocation();
  const lastItem = R.last(cartItems);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  // const productsTotal = cartItems.reduce(
  //   (acc, product) =>
  //     acc +
  //     R.sum([product.price, product?.profit_added ?? 0]) * product?.quantity,
  //   0
  // );
  const[qty,setQty]=useState(1);
  const productsTotal =
    R.sum([product?.price!, product?.profit_added ?? 0]) * productQty;
  const total = R.sum([
    productsTotal,
    parseFloat(deliveryPrice as unknown as string) || 0,
  ]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }
  if (!product) {
    // While the product is still being fetched, display a loading message
    return <div>Loading...</div>;
  }

  return (
    <div>
     
      <div className=" ">
     
       <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            <div className="mx-auto mt-6  w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product?.media?.map((image: any) => (
                  <Tab
                    key={image?.id}
                    className="relative flex lg:h-24 h-16 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none  focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={image?.avatar ?? ""}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <span
                          className={clsx(
                            selected ? "ring-2 ring-pink-500" : "ring-transparent",
                            "pointer-events-none absolute inset-0 rounded-md  ring-offset-2"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
              {product?.media?.map((image: any) => (
                <Tab.Panel key={image?.id}>
                  <img
                    src={image?.avatar ?? ""}
                    alt={product?.title}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-xl lg:text-3xl font-medium tracking-tight text-gray-900">
              {product?.title}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                <>
                  {product?.currency}{" "}
                  {numeral(
                    _.sum([product.price ?? 0, product.profit_added ?? 0])
                  ).format("#,#")}
                </>
              </p>
            </div>
            <div className="flex mt-4 mb-4 flex-row space-x-4">
                  <div className="rounded-full bg-pink-500 text-white px-4 py-1.5">Full Copy</div>
                  <div className="rounded-full border border-gray-400 px-4 py-1.5">Commentory</div>
            </div>

            <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                          <button
                           onClick={() => setQty(qty - 1)}
                           >
                            <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                           <div>{qty}</div>
                          <button
                            onClick={() => {
                              setQty(qty+1)
                            }}
                          >
                            <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                        </div>

           

            <form className="mt-6">
              <div className="sm:flex-col mt-10 flex">
              {/* <div 
              className="bg-black text-center p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white w-full flex-1"
               onClick={()=>navigate("/digital-product-delivery")}>Order</div> */}

                <button
                  type="button"
                  onClick={()=>navigate(`/digital-checkout-1/${product?.id}/${qty}`)}
                  className="bg-pink-500 text-center p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white max-w-xs flex-1"
                >
                  Proceed to Checkout
                </button>
                
              </div>
            </form>
            <div className="mt-6">
              <h3 className="font-medium">Product Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: product?.description ?? "" }}
              />
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          <section>
              
             

            <div className="mx-auto mt-16 w-full flex flex-row space-x-8 max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
              <div className="font-medium text-lg">Ratings & Reviews</div>
              <div>{product?.overalReview}</div>
              <div className=" flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                product?.overalReview! > rating ? 'text-yellow-500' : 'text-gray-300',
                                'h-5 w-5 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
              </div>
              {/* <div className="flex flex-col mt-4 space-y-2">
                  <div className="flex flex-row space-x-4  items-center">
                    <div className="w-24">Excellent</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.excellent}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Very Good</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.veryGood}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Good</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.good}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Average</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.average}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Poor</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.poor}</div>
                  </div>
              </div> */}
              <div className="flex flex-col mt-4 space-y-2">
  {[
    { label: 'Excellent', value: product?.reviewBreakDown.breakDown.excellent },
    { label: 'Very Good', value: product?.reviewBreakDown.breakDown.veryGood },
    { label: 'Good', value: product?.reviewBreakDown.breakDown.good },
    { label: 'Average', value: product?.reviewBreakDown.breakDown.average },
    { label: 'Poor', value: product?.reviewBreakDown.breakDown.poor }
  ].map(({ label, value }) => (
    <div className="flex flex-row space-x-4 items-center" key={label}>
      <div className="w-24">{label}</div>
      <div className="w-52 h-2 bg-slate-300 rounded-lg relative">
  <div
    className="h-2 bg-yellow-500 rounded-lg absolute left-0 top-0"
    style={{ width: `${(value! / product?.reviewBreakDown.totalReviews!) * 100}%` }}
  />
</div>
<div>{value}</div>
    </div>
  ))}
</div>
            </section>
          </div>
        </div>
      </div>
    </div>
   
    </div>
  );
};

export default StorefrontOrderPage;
