import { useMutation } from "@apollo/client";
import { CREATE_DIGITAL_ORDER } from "../graphql/mutations/mutations";
import { CreateDigitalOrderMutation, CreateDigitalOrderMutationVariables } from "../graphql/mutations/__generated__/mutations";

export const useCreateDigitalOrder = () => {
    const [createDigitalOrder, { loading, error }] = useMutation<CreateDigitalOrderMutation, CreateDigitalOrderMutationVariables>(CREATE_DIGITAL_ORDER, {

    });

    return {
        createDigitalOrder,
        loading, error
    }
};
