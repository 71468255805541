import React from "react";
import CartComponent from "./CartComponent";

type Props = {};

const CartContainer = (props: Props) => {
  return <CartComponent />;
};

export default CartContainer;
