import errorImage from "./404-error.png"
const ErrorPage = () => {
    return ( 
        <div>
            <div className="text-center mt-32 mb-20   w-full h-full">
     {/* <div className="text-8xl">404</div> */}
      <div><img className="mx-auto  -mt-32" src={errorImage} alt="" /></div>
     <div className="text-2xl -mt-16 text-orange-400 font-bold">Sorry, we did not find the shop you are looking for.</div>
      <div className="w-1/2 m-auto">The shop you are looking for might have been removed, had it's name changed or moved to a different location.</div>
    </div>;
        </div>
     );
}
 
export default ErrorPage;