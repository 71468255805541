import { useEffect } from "react";
import App from "./App";
import useShop from "./hooks/useShop";
import { useAppDispatch } from "./app/hooks";
import { setShop } from "./features/shop/shop-slice";
import Loader from "./layouts/StoreFront/components/Loader";
import ErrorPage from "./layouts/404/404";

const Root = () => {
  const dispatch = useAppDispatch();

  const { shop, loading } = useShop({
    filter: {
      slug: {
        eq: `${window.location.host
          .split(".")
          .slice(0, window.location.href.includes("localhost") ? -1 : -2)}`,
      },
    },
  });

  useEffect(() => {
    if (shop) {
      dispatch(setShop(shop));
    }
  }, [dispatch, shop]);

  if (loading) return <Loader />;

  if (!shop) return <ErrorPage />;

  return <App />;
};

export default Root;
