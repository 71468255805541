import { useQuery } from "@apollo/client";
import { GET_REVIEWLENGTH } from "../graphql/queries/queries";
import { QueryQuery,QueryQueryVariables } from "../graphql/queries/__generated__/queries";
 const useReviewLength = (variables?:QueryQueryVariables) => {
    const { data, loading, error, refetch, fetchMore } = useQuery<QueryQuery, QueryQueryVariables>(GET_REVIEWLENGTH, {
        fetchPolicy: "network-only",
        variables
      });
    return {
        reviewlength: data?.getReviewsLength ,
        loading,
    };
};
export default useReviewLength;
 
