import { useQuery } from "@apollo/client"
import { GET_PRODUCT } from "../graphql/queries/queries"
import { GetProductQuery, GetProductQueryVariables } from "../graphql/queries/__generated__/queries"

export const useProduct = (variables?: GetProductQueryVariables) => {
    const { data, loading, error, fetchMore, networkStatus,refetch } = useQuery<GetProductQuery, GetProductQueryVariables>(GET_PRODUCT, {
        variables,
        notifyOnNetworkStatusChange: true
    })
    return {
        product: data?.getProduct,
        loading,
        error,
        fetchMore,
        networkStatus,
        refetch
    }
}