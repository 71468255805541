import { Fragment, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Modal from "../../../components/Modal";
import SignIn from "../../../components/SignIn/SignIn";
import SignUp from "../../../components/SignUp/SignUp";
import { signOut } from "../../../features/auth/auth-slice";

const SignInCreateAccount = () => {
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  const [openSignInModal, setSignInModal] = useState(false);
  const [openSignUpModal, setSignUpModal] = useState(false);
  return (
    <Fragment>
      {" "}
      <div className="flex items-center space-x-6">
        {!userInfo ? (
          <>
            <button
              
              className="text-sm font-medium text-white hover:text-gray-100"
              onClick={() => setSignInModal(true)}
            >
              Sign in
            </button>
            {/* <button
              
              onClick={() => setSignUpModal(true)}
              className="text-sm font-medium text-white hover:text-gray-100"
            >
              Create an account
            </button> */}
          </>
        ) : (
          <button
            
            className="text-sm font-medium text-white hover:text-gray-100"
            onClick={() => dispatch(signOut())}
          >
            Log out
          </button>
        )}
      </div>
      <Modal showCloseIcon show={openSignInModal} setShow={setSignInModal}>
        <SignIn setShowSignIn={setSignInModal} />
      </Modal>
      <Modal showCloseIcon show={openSignUpModal} setShow={setSignUpModal}>
        <SignUp setShow={setSignUpModal} setShowSignIn={setSignInModal} />
      </Modal>
    </Fragment>
  );
};

export default SignInCreateAccount;
