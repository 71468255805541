import { GET_CARTS } from "../graphql/queries/queries";
import { GetCartsQuery,GetCartsQueryVariables } from "../graphql/queries/__generated__/queries";
import { useQuery } from "@apollo/client";



const useOrderCart=(variables?:GetCartsQueryVariables)=>{
 const{data,loading,error,fetchMore,refetch} =useQuery<GetCartsQuery,GetCartsQueryVariables> (GET_CARTS,{
fetchPolicy:"no-cache",
variables
});
return {
  cartorders :data?.getCarts ?? [] ,
  loading,
  error,
  refetch,
  fetchMore
};
};

export default useOrderCart;
