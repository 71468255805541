import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./app/store";
// import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ApolloProvider } from "@apollo/client";
import "./index.css";
import client from "./graphql/client";
import Root from "./Root";
import { MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import {  HelmetProvider } from 'react-helmet-async';

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <MantineProvider withNormalizeCSS withGlobalStyles>
    
    <NotificationsProvider>
      <ApolloProvider client={client}>
        <Provider store={store}>
          
          <Root />
        </Provider>
      </ApolloProvider>
    </NotificationsProvider>
    
  </MantineProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
