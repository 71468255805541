import { GET_DIGITAL_ORDER } from "../graphql/queries/queries";
import { GetDigitalOrderQuery,GetDigitalOrderQueryVariables } from "../graphql/queries/__generated__/queries";
import { useQuery } from "@apollo/client";



const useDigitalOrder=(variables?:GetDigitalOrderQueryVariables)=>{
 const{data,loading,error,fetchMore,refetch} =useQuery<GetDigitalOrderQuery,GetDigitalOrderQueryVariables> (GET_DIGITAL_ORDER,{
fetchPolicy:"no-cache",
variables
});
return {
  digitalorder :data?.getDigitalOrder  ,
  loading,
  error,
  refetch,
  fetchMore
};
};

export default useDigitalOrder;
