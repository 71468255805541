import {
  
  ShoppingCartIcon,
  
} from "@heroicons/react/24/outline";


import React from "react";
import { Link, NavLink } from "react-router-dom";

import useCart from "../../hooks/useCart";

import Logo from "../Logo/Logo";

type Props = {
  showCartDrawer: boolean;
  setShowCartDrawer: (val: boolean) => void;
};

const HeaderComponent = ({ setShowCartDrawer }: Props) => {
  const { cartLength } = useCart();
  // const dispatch = useAppDispatch();
  // const { userInfo } = useAppSelector((state) => state.auth);

  // const logout = () => {
  //   dispatch(signOut());
  // };

  return (
    <>
      <header className="bg-white w-full shadow-sm p-2">
        <div className="max-w-6xl  mx-auto flex items-center justify-between py-2 gap-x-4">
          <div>
            <NavLink to="/">
              {/* <img src={logo} alt="storefront logo" /> */}
              <Logo />
            </NavLink>
          </div>
          <div className="hidden w-1/2 md:flex gap-x-2 ">
            <input
              type="search"
              className=" py-2 md:px-4 border border-gray-300 outline-0 w-full rounded-md"
              placeholder="search for an item"
            />
            <button className="bg-mainPink text-white px-4 rounded-md">
              Search
            </button>
            
          </div>
          
          <div className="flex items-center gap-x-4">
            <Link
             to="/cart"
            >
            <span
              className="relative inline-block cursor-pointer"
              // onClick={() => setShowCartDrawer(true)}
            >
              <ShoppingCartIcon className="w-7" />
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-mainPink rounded-full">
                {cartLength}
              </span>
              
            </span>
            </Link>
            {/* </NavLink> */}
            

            
          </div>
        </div>
      </header>
    </>
  );
};

export default React.memo(HeaderComponent);
