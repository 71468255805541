import moment from "moment"; 
import Modal from "../../components/Modal/Modal";
import logo from "../StoreFront/components/Emptycart.png";
import PackageDetails from "./PackageDetails";
import emptyorder from "../../assets/emptyorder.png";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useState } from "react";
import useOrderCart from "../../hooks/useOrderCart";
    interface Props {
        orders: any;
      }
const Order = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
    const[openPackageDetails,setOpenPackageDetails]=useState(false);
    const openDetails = () => {
        // setShowSignIn(false);
        setOpenPackageDetails(true);
       
      };  
      const navigate = useNavigate();
      const handleViewOrderClick = (trackingCode:any) => {
       
        navigate(`/track-your-order?trackingCode=${trackingCode}`);
      };
      function classNames(...classes: string[]) {
        return classes.filter(Boolean).join(' ')
      }   

      const {cartorders}=useOrderCart({
        filter:{
          customer_id:{
            eq : userInfo?.id 
          }
        }
      });
    
         
            return (
              
    
    <main className="mx-auto max-w-2xl pb-24 pt-8 sm:px-6 sm:pt-16 lg:max-w-7xl lg:px-8">
      
    

    {/* Products */}
    <section aria-labelledby="products-heading" className="mt-6">
      <h2 id="products-heading" className="sr-only">
        Products purchased
      </h2>
      {cartorders.length==0 ?
       <div className="flex flex-col space-y-2 m-auto justify-center"> 
        <div>
          <img src={emptyorder} className="w-96 h-84 -mt-16 m-auto -mb-16" alt="" />
        </div>
        <div className="m-auto  pb-2 text-2xl">You have no orders yet</div>
        <Link to="/" className=" m-auto bg-pink-600 text-white py-1 rounded-sm px-2">
        <div className=" m-auto bg-pink-600 text-white py-1 rounded-sm px-2">Start shopping</div>
        </Link>
       </div> :
      
      <div className="space-y-8">
        <div className="text-lg">My Orders</div>
        {cartorders.map((orderitem:any) => (
          <div
            key={orderitem?.cart_number}
            className="border-b border-t border-gray-200 flex flex-col bg-white shadow-sm sm:rounded-lg sm:border"
          >
            <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
              <div className="sm:flex lg:col-span-7">
                {/* <div className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                  <img
                    src={orderitem?.product?.media?.avatar}
                    // alt={product.imageAlt}
                    className="h-full w-full object-cover object-center sm:h-full sm:w-full"
                  />
                </div> */}

                <div className="mt-6 sm:ml-6 sm:mt-0">
                  <h3 className="text-base font-medium text-gray-900">
                      Cart info
                  </h3>
                   <p className="mt-2 text-sm font-medium text-gray-500"><span>Order created at : </span><time dateTime={orderitem?.createdAt}>
        {new Date(orderitem?.createdAt).toLocaleDateString()}
      </time> </p>
                   
                  <p className="mt-3 text-sm text-gray-500">Cart Number: {orderitem?.cart_number}</p>
                  <p className="mt-3 text-sm text-gray-500"> <span>Qty:</span> {orderitem?.cartLength}</p>
                </div>
              </div>

              <div className="mt-6 lg:col-span-5 lg:mt-0">
                <dl className="grid grid-cols-2 gap-x-6 text-sm">
                  <div>
                    <dt className="font-medium text-gray-900">Delivery Info</dt>
                    <dd className="mt-3 text-gray-500">
                      <span className="block">{orderitem?.delivery?.location}</span>
                      <span className="block">{shop?.currency} {orderitem?.delivery?.price}</span>
                      
                    </dd>
                  </div>
                  <div>
                    <dt className="font-medium text-gray-900">Order Info</dt>
                    <dd className="mt-3 space-y-3 text-gray-500">
                       <span className="block"><span>Order total : </span>{shop?.currency} {orderitem?.order_total} </span> 
                      
                      
                    </dd>
                  </div>
                </dl>
              </div>
              
            </div>
            <div className=" ">
                <button
                onClick={() => handleViewOrderClick(orderitem?.tracking_code)}
                 className="  float-right mr-8 -mt-8 mb-8 border rounded-md w-32 py-2 text-sm text-white bg-pink-600">View Order</button>
              </div>

            {/* <div className="border-t border-gray-200 px-4 py-6 sm:px-6 lg:p-8">
              <h4 className="sr-only">Status</h4>
              <p className="text-sm font-medium text-gray-900">
                {orderitem?.status} on <time dateTime={orderitem?.createdAt}>{orderitem?.createdAt}</time>
              </p>
              <div className="mt-6" aria-hidden="true">
                <div className="overflow-hidden rounded-full bg-gray-200">
                  <div
                    className="h-2 rounded-full bg-orange-500"
                    style={{ width: `calc(${orderitem?.status === "PENDING" ? "25%" : orderitem?.status === "ORDER PACKED" ? "50%" :  orderitem?.status === "PICKED UP" ? "75%" : "100%"})` }}
                  />
                </div>
                <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                  <div className="text-gray-500">Order placed</div>
                  <div className={classNames(orderitem?.status === "" ? 'text-orange-500' : '', 'text-center ')}>
                    Processing
                  </div>
                  <div className={classNames(orderitem?.status === "" ? 'text-orange-500' : '', 'text-center')}>
                    Shipped
                  </div>
                  <div className={classNames(orderitem?.status === "" ?'text-orange-500' : '', 'text-right')}>
                    Delivered
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        ))}
      </div>
      }
    </section>
    </main>
         );
        }
 
export default Order;