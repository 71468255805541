
import React, {   useRef, useState } from "react";

import { signinUser } from "../../features/auth/auth-slice";
import { CUSTOMER_SIGNIN } from "../../graphql/mutations/mutations";
import {
  CustomerSignInMutation,
  CustomerSignInMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { notification } from "antd";
import { useForm, SubmitHandler } from "react-hook-form";
import { REQUEST_OTP } from "../../graphql/mutations/mutations";
import { RequestOtpMutation,RequestOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { useAppDispatch } from "../../app/hooks";
import Button from "../buttons/buttons";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import Modal from "../Modal";
import TextInput from "../TextInput/TextInput";
import VerifyOtpModal from "../VerifyOTP/VerifyOtpModal";
import SignUp from "../../components/SignUp/SignUp";


interface Props {
  setShowSignIn: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignIn = ({ setShowSignIn }: Props) => {
  const [openSignUpModal, setSignUpModal] = useState(false);
  const [openVerifyOtpModal,setVerifyOtp] = useState(false);
  const [phonedata,setphone]=useState<string>("");
  const [token, settoken]= useState<string>("");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  
  const openChildModal = () => {
    // setShowSignIn(false);
    setSignUpModal(true);
   
  };
  
  const updatephone=(phonedata:string)=>{
    setphone(phonedata)
  }
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RequestOtpMutationVariables>();

  const [RequestOtp, { loading }] = useMutation<
    RequestOtpMutation,
    RequestOtpMutationVariables
  >(REQUEST_OTP);
  const [signIn, {  }] = useMutation<
    CustomerSignInMutation,
    CustomerSignInMutationVariables
  >(CUSTOMER_SIGNIN);

  const onSubmit: SubmitHandler<CustomerSignInMutationVariables> = (values) => {
    console.log(values);
    // RequestOtp({
    //   variables: {
    //     ...values,
    //   },
      
    //   onCompleted: (res) => {
    //     if (res?.requestOTP) {
    //       setVerifyOtp(true);
    //       setphone(values?.input?.phone!);
    //       settoken(res?.requestOTP.token!);
           
    //       return;
    //     } else {
    //       notification.error({
    //         message: "please contact support",
    //       });
    //       return;
    //     }
    //   },
    //   onError: (err) => {
    //     notification.error({
    //       message: err?.message,
    //     });
    //   },
    // });
    signIn({
      variables: {
        ...values,
      },
      onCompleted: (res) => {
        if (res?.customerSignIn) {
          console.log(res.customerSignIn);
          dispatch(signinUser(res?.customerSignIn));
          setShowSignIn(false);
          navigate("/");
          return;
        } else {
          notification.error({
            message: "please contact support",
          });
          return;
        }
      },
      onError: (err) => {
        notification.error({
          message: err?.message,
        });
      },
    });
  };

  return (
   <div>
    <div className="h-80">
      <form
        className="flex-1 flex flex-col justify-between h-full "
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="flex flex-col">
          <h4 className="text-3xl text-tendo-200 my-2">Sign In</h4>
          <p className="text-sm font-light">
            provide phone number that was used to sign up
          </p>
          <div className="my-6">
            <label
              htmlFor="name"
              className="block text-sm font-medium lg:text-black "
            >
              Phone Number
            </label>
            <div className="mt-2 relative rounded-md ">
              <TextInput
                {...register("input.phone", {
                  required: {
                    value: true,
                    message: "Required",
                  },
                })}
                type="phone"
                id="name"
               
                className="border px-2"
                placeholder="eg. 0202718168"
              />
              <ErrorMessage errors={errors} name="input.phone" />
            </div>
          </div>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            disabled={loading}
            onClick={() => setShowSignIn(false)}
            type="button"
          >
            Cancel
          </Button>

          <button
            // variant="primary"
            disabled={loading}
            // loading={loading}
            type="submit"
            className="disabled:bg-gray-300 disabled:cursor-wait flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-amber-500 hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Submit
          </button>
        </div>
        <div className="text-center">
        <button
              
            onClick={() =>  openChildModal() }
              className="text-sm  text-gray-400 "
            >
              Don't have an account yet? <span className="text-amber-600">Sign Up.</span>
            </button>
            {/* <Modal showCloseIcon show={openSignUpModal} setShow={setSignUpModal}>
        <SignUp setShow={setSignUpModal} setShowSignIn={setShowSignIn} />
      </Modal>
      <Modal showCloseIcon show={openVerifyOtpModal} setShow={setVerifyOtp}>
        <VerifyOtpModal setShow={setVerifyOtp} phonedata={phonedata} token={token} setShowSignIn={setShowSignIn}   />
      </Modal> */}
        </div>
      </form>
    </div>
    <Modal showCloseIcon show={openSignUpModal} setShow={setSignUpModal}>
        <SignUp setShow={setSignUpModal} setShowSignIn={setShowSignIn} />
      </Modal>
      <Modal showCloseIcon show={openVerifyOtpModal} setShow={setVerifyOtp}>
        <VerifyOtpModal setShow={setVerifyOtp} phonedata={phonedata} token={token} setShowSignIn={setShowSignIn}   />
      </Modal>
    </div>
    
    
   
    
  );
};

export default SignIn;
