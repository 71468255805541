import { isNil } from "ramda";
import { get } from "lodash";

type Props = {
  errors: Object;
  name: string;
};

const ErrorMessage = ({ errors, name }: Props) => {
  return (
    <div>
      {!isNil(get(errors, name)) && (
        <div className="text-red-500">{get(errors, name)?.message}</div>
      )}
    </div>
  );
};

export default ErrorMessage;
