import { MinusIcon, PlusIcon,TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import plural from "pluralize";

import React, { Fragment } from "react";
import { useAppDispatch } from "../../app/hooks";
import {
  decreaseQty,
  increaseQty,
  removeFromCart,
} from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";


type Props = {};

const CartPage = (props: Props) => {
  const { cartLength, cartItems } = useCart();
  const dispatch = useAppDispatch();
  
  return (

    <Fragment >
      
      <div className="flex items-end space-x-2   ">
        <h1 className="font-bold text-3xl">Cart</h1>
        <h3 className="small-caps font-light text-xl text-gray-500">
          {cartLength} {plural("item", cartLength)}
        </h3>
      </div>

      <div className="py-4 space-y-4  lg:mr-6">
        {cartItems?.map((item, idx) => (
          <div
            key={idx}
            className="flex justify-between h-48 flex-col w-100% border border-gray-300  px-2 lg:px-4 pt-4 lg:pt-4  rounded-lg md:flex-row items-start"
          >
            <div className="flex flex-row space-x-4   ">
              <div className="w-30% h-36">
                <img src={item?.avatar} className="h-32 w-32 lg:h-36 lg:w-36" alt="" />
              </div>
              <div className="flex flex-col justify-between w-70%  ">
                <div className="flex    ">
                <div className="font-medium w-32 text-sm lg:text-base lg:w-52 ">{item?.title}</div>
                <div className="font-medium text-lg  ">
                {item?.currency} {_.sum([item?.price,item?.profit_added])}
                
              </div>
                </div>
                
                <span>{item?.combination_string?.split("&").join(", ")}</span>
                
                <div className="flex justify-between  w-full items-center flex-row lg:flex-row">
                  <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                    <button onClick={() => dispatch(decreaseQty(idx))}>
                      <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                    </button>
                    {/* <div>Qty:</div> */}
                    <div>{item?.quantity}</div>
                    <button onClick={() => { console.log("Increase clicked"); dispatch(increaseQty(idx)); }}>
                      <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                    </button>
                  </div>
                  <div className="flex flex-row space-x-2">
                  <TrashIcon className="w-4 h-4 text-red-600"/>
                  <button
                    onClick={() => dispatch(removeFromCart(item))}
                    className="text-red-600"
                  >
                   
                    Remove
                  </button>
                  </div>
                </div>
              </div>
            </div>
            {/* <div>
              <span>
                {item?.currency} {item?.price}
              </span>
            </div> */}
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default CartPage;
