import { MinusIcon, PlusIcon, TrashIcon,StarIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import _ from "lodash";
import plural from "pluralize";
import React, { Fragment, useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import useDigitalOrder from "../../hooks/useDigitalOrder";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  decreaseQty,
  increaseQty,
  removeFromCart,
} from "../../features/cart/cart-slice";
import useCart from "../../hooks/useCart";
import Show from "../../components/Show";
import { Helmet } from "react-helmet-async";
import { useLocation, Outlet, useNavigate, useParams } from "react-router-dom";
import * as R from "ramda";
import numeral from "numeral";
import DukePaymentButton from "../../components/duke-payment/duke-payment";
import { useProduct } from "../../hooks/useProduct";
const DigitalCheckout1 = () => {
  let urlParams = new URLSearchParams(window.location.search);
  const invoice = urlParams.get("invoice");
  const { digitalorder } = useDigitalOrder({
    filter: {
      id: {
        eq: invoice,
      },
    },
  });
  console.log(digitalorder);
  const { cartLength, cartItems, deliveryPrice } = useCart();
  const navigate = useNavigate();
  const { id } = useParams();
  const { qty } = useParams();
  const initialQty = Number(qty) || 1; 
  console.log(digitalorder?.productId)
  const { shop } = useAppSelector((state) => state?.shop);
  
  const { product, refetch } = useProduct({
    "filter": {
      "id": {
        "eq":id,
      },
      // shop: {
      //   shop_id: {
      //     eq: shop?.id,
      //   },
      // },
    },
  });
  
  const [productQty,setProductQty]=useState(initialQty);
  const { pathname } = useLocation();
  const lastItem = R.last(cartItems);
  const dispatch = useAppDispatch();
  const { userInfo } = useAppSelector((state) => state.auth);
  // const productsTotal = cartItems.reduce(
  //   (acc, product) =>
  //     acc +
  //     R.sum([product.price, product?.profit_added ?? 0]) * product?.quantity,
  //   0
  // );
  // const[qty,setQty]=useState(1);
  const productsTotal =
    R.sum([product?.price!, product?.profit_added ?? 0]) * productQty;
  const total = R.sum([
    productsTotal,
    parseFloat(deliveryPrice as unknown as string) || 0,
  ]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <div>
      <div className="flex flex-row w-full ">
      <div className="flex flex-col sm:flex-row w-[80%] mx-auto   ">
        <Fragment>
          <div className="p-4 mt-4   sm:mt-4 bg-[#FFFFFF] rounded-lg flex items-center justify-center">
            <div className="py-1 space-y-4  lg:mr-6">
              <div className="font-medium">Checkout Items</div>
              <div className="flex flex-row space-x-4 border  border-gray-400 rounded-lg p-4  ">
                <div className="w-20% h-36">
                  <img
                    src={product?.avatar}
                    className="h-28 w-28 lg:h-36 lg:w-36 rounded-lg"
                    alt=""
                  />
                </div>
                <div className="flex flex-col space-y-8 sm:space-y-0 sm:justify-between w-80%  ">
                  <div className="flex    ">
                    <div className="font-medium w-20 text-sm lg:text-base lg:w-52 ">
                      {product?.title}
                    </div>
                    <div className="font-medium text-md   ">
                      {product?.currency}{" "}
                      {_.sum([product?.price, product?.profit_added])}
                    </div>
                  </div>

                  <span>
                    {product?.combination_string?.split("&").join(", ")}
                  </span>

                  <div className="flex justify-between  sm:-mb-0  w-full items-center flex-row lg:flex-row">
                    <div className="border  flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                      <button onClick={() => setProductQty(productQty - 1)}>
                        <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                      </button>
                      
                      <div>{productQty}</div>
                      <button
                        onClick={() => {
                         
                          setProductQty(productQty + 1);
                        }}
                      >
                        <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                      </button>
                    </div>
                    <div className="flex flex-row space-x-2">
                      <TrashIcon className="w-4 h-4 text-red-600" />
                      <button
                       
                        className="text-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
        <Fragment>
          <div className="  lg:max-w-7xl  flex lg:flex-row   flex-col  lg:mx-auto lg:space-x-32">
            <Helmet>
              <title>Cart</title>
            </Helmet>
            <div className="flex-1">
              <Outlet />
            </div>
            <div className=" border-gray-300 bg-[#FFFFFF]  my-4 p-4  h-fit w-[330px] sm:w-96  rounded-lg space-y-3">
              <h2 className="font-bold text-xl">Order Summary </h2>
              <hr />

              <div className="space-y-4 w-full mb-11">
                <div className="flex justify-between text-base">
                  <p className="font-light">Item(s)</p>
                  <p className="font-medium">{productQty}</p>
                </div>
                <div className="flex justify-between text-base">
                  <p className="font-light">Subtotal</p>
                  <p className="font-medium">
                    {product?.currency}{" "}
                   
                    {numeral(
                      R.sum([product?.price!, product?.profit_added ?? 0])
                    ).format("#,#.##")}
                  </p>
                </div>
                <Show if={false}>
                  <div className="flex justify-between text-base">
                    <p className="font-light">Discount</p>
                    <p className="font-medium">{lastItem?.currency} 100</p>
                  </div>
                </Show>
             

                <div className="flex justify-between text-base">
                  <p className="font-light">TOTAL</p>
                  <p className="font-medium">
                    {lastItem?.currency}{" "}
                    {numeral(productsTotal).format("#,#.##")}
                  </p>
                </div>
                <div className="my-11">
                  <div
                    className="bg-pink-600 text-center p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white w-full flex-1"
                    onClick={() =>
                      navigate(
                        `/digital-product-delivery/${id}/${productQty}`
                      )
                    }
                  >
                    Order
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </Fragment>
      </div>
       
    </div>
   
    </div>
  );
};

export default DigitalCheckout1;
