import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import _ from "lodash";
import { Product } from "../../graphql/types";
import * as R from "ramda";


type iProduct = Product & { quantity: number }
interface ICart {
  cartItems: iProduct[];
  cartTotal: number;
  customer_address: any;
  customer_delivery_location: any;
  deliveryPrice: number;
}

const initialState: ICart = {
  cartItems: [],
  cartTotal: 0,
  deliveryPrice: 0,
  customer_address: null,
  customer_delivery_location: null,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    // addToCart: (state, { payload }: PayloadAction<any>) => {
    //   const isAlreadyAdded = state.cartItems.find(
    //     (item) => payload.id === item.id
    //   );

    //   if (!isAlreadyAdded) {
        
    //     state.cartItems = [{ ...payload, quantity: 1 }, ...state.cartItems];
    //     state.cartTotal = state.cartItems?.reduce<number>((total, cur) => {
    //       return Number(total) + _.sum([cur?.price, cur?.profit_added]);
    //     }, 0);
    //     return;
    //   }
    //   state.cartItems = [{ ...payload, quantity: 1 }, ...state.cartItems];
    //   state.cartTotal = state.cartItems?.reduce<number>((total, cur) => {
    //     return Number(total) + _.multiply(cur?.quantity, _.sum([cur?.price, cur?.profit_added]));
    //   }, 0);
    // },
    addToCart: (state, { payload }: PayloadAction<any>) => {
      const existingItemIndex = state.cartItems.findIndex(
        (item) => payload.id === item.id
      );
    
      if (existingItemIndex !== -1) {
        // Item already exists in the cart, update the quantity
        state.cartItems[existingItemIndex].quantity += 1;
      } else {
        // Item doesn't exist, add it to the cart
        state.cartItems = [{ ...payload, quantity: 1 }, ...state.cartItems];
      }
    
      state.cartTotal = state.cartItems.reduce<number>((total, cur) => {
        return Number(total) + _.multiply(cur?.quantity, _.sum([cur?.price, cur?.profit_added]));
      }, 0);
    },
    
    removeFromCart: (state, { payload }: PayloadAction<any>) => {
      const newCartItems = state.cartItems.filter(
        (item) => item.id !== payload.id
      );
      state.cartItems = newCartItems;
      state.cartTotal =
        state.cartTotal - _.sum([payload?.price, payload?.profit_added]);
    },
    clearCart: (state) => {
      state.cartItems = [];
      state.cartTotal = 0;
      state.customer_delivery_location = null;
      state.customer_address = null;
      state.deliveryPrice = 0
    },
    setCustomerAddress: (state, { payload }: PayloadAction<any>) => {
      state.customer_address = payload;
    },
    setCustomerDeliveryLocation: (state, { payload }: PayloadAction<any>) => {
      state.customer_delivery_location = payload;
    },
    setdeliveryPrice: (state, { payload }: PayloadAction<any>) => {
      state.deliveryPrice = payload;
    },
    increaseQty: (state, action: PayloadAction<number>) => {
      state.cartItems = R.adjust(action.payload, (el) => ({ ...el, quantity: parseInt(el?.qty?.toString() ?? Infinity.toString()) > el?.quantity ? el.quantity + 1 : el.quantity }), state.cartItems)
    },
    decreaseQty: (state, action: PayloadAction<number>) => {
      state.cartItems = R.adjust(action.payload, (el) => ({ ...el, quantity: el.quantity < 2 ? el.quantity : el.quantity - 1 }), state.cartItems)
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  setCustomerAddress,
  setCustomerDeliveryLocation,
  setdeliveryPrice,
  clearCart,
  decreaseQty,
  increaseQty
} = cartSlice.actions;

export default cartSlice.reducer;
