import Emptycard from "../../assets/emptycard.png";

import { ExclamationCircleIcon } from "@heroicons/react/24/outline";
const PaymentInformation = () => {
  return (
    <div className="max-w-3xl bg-white absolute md:left-60 flex flex-col md:pt-6 md:pl-12">
      <div className="">Payment Information</div>
      <section className="max-w-3xl lg:max-w-7xl border my-2 bg-gray-200 "></section>
      <div className=" max-w-3xl lg:max-w-7xl  mb-4 py-2 px-2 bg-lightBlue rounded-sm flex flex-col">
        <div className="flex flex-row ">
          <div className="">
            <ExclamationCircleIcon className="w-4" />
          </div>
          <div className="text-sm ml-2 font-bold  ">Add a checkout method</div>
        </div>
        <div className="text-sm ml-6">
          Checkout is faster when you have already added your credit or debit
          card
        </div>
      </div>
      <div className="flex flex-col lg:flex-row py-8">
        <div className="flex flex-col">
          <div className="block text-md font-bold text-gray-700">
            Add Payment Information
          </div>
          <div className="mx-4 mt-8">
            <form action="">
              <div className="flex ">
                <div className="flex flex-col mx-4 my-2">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder="Fetty"
                    id=""
                    className=" focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300  w-32 text-xs rounded-md px-2 py-2"
                  />
                </div>
                <div className="flex flex-col mx-4 my-2">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name=""
                    placeholder="Rapcha"
                    id=""
                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300  w-32 text-xs rounded-md px-2 py-2"
                  />
                </div>
              </div>
              <div className="mx-4 my-2 flex flex-col">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Card Number
                </label>
                <input
                  type="number"
                  name=""
                  placeholder="0000 0000 000"
                  id=""
                  className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300  w-72 text-xs rounded-md  pl-2 py-2"
                />
              </div>
              <div className="flex my-2">
                <div className="flex flex-col mx-4 my-2">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700"
                  >
                    Expiration Date
                  </label>
                  <input
                    type="date"
                    name=""
                    placeholder="07/23"
                    id=""
                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300 w-32 text-xs rounded-md px-2 py-2"
                  />
                </div>
                <div className="flex flex-col mx-4 my-2">
                  <label
                    htmlFor=""
                    className="block text-sm font-medium text-gray-700"
                  >
                    Security Code
                  </label>
                  <input
                    type="number"
                    name=""
                    placeholder="000"
                    id=""
                    className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300  w-32 text-xs rounded-md px-2 py-2"
                  />
                </div>
              </div>
              <div className="mx-4 flex flex-col my-2">
                <label
                  htmlFor=""
                  className="block text-sm font-medium text-gray-700"
                >
                  Country
                </label>
                <input
                  type="text"
                  name=""
                  placeholder="0000 0000 000"
                  id=""
                  className="focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border  border-gray-300  w-72 text-xs rounded-md  pl-2 py-2"
                />
              </div>

              <div>
                <button
                  // onClick={() => navigate("/")}
                  type="submit"
                  className="w-2/4 flex justify-center py-1 px-4 my-8 ml-20 border border-transparent rounded-sm shadow-sm text-sm font-medium text-white bg-mainPink hover:bg-darkPink focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add Cards
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <div className=" sm:hidden lg:block md:w-0.5 md:h-96 ml-20 mr-4 bg-lightGray"></div> */}
        <div className="flex flex-col">
          <div className="block text-md font-bold text-gray-700">
            Payment Method
          </div>
          <div className=" mx-32 my-12 w-48 flex justify-center">
            <img src={Emptycard} alt="" className=" " />
          </div>
          <div className="text-gray-500 m-auto text-xs">
            No payment Method Added
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentInformation;
