import { useQuery } from "@apollo/client";
import { GET_USER } from "../graphql/queries/queries";
import { UserQuery, UserQueryVariables } from "../graphql/queries/__generated__/queries";


const useUser = (variables?: UserQueryVariables) => {
  const { data, loading, error, refetch, fetchMore } = useQuery<UserQuery, UserQueryVariables>(GET_USER, {
    fetchPolicy: "network-only",
    variables
  });

  return {
    user: data?.user,
    loading,
    error,
    refetch,
    fetchMore
  };
};

export default useUser;
