import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GetShopQuery } from "../../graphql/queries/__generated__/queries";

interface IShopFace {
  shop: GetShopQuery["getShop"] | null;
}

const initialState: IShopFace = {
  shop: null,
};

export const shopSlice = createSlice({
  name: "shop",
  initialState,
  reducers: {
    setShop: (state, { payload }: PayloadAction<any>) => {
      state.shop = payload;
    },
  },
});

export const { setShop } = shopSlice.actions;

export default shopSlice.reducer;
