import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";

const Logo = () => {
  const { shop } = useAppSelector((state) => state?.shop);
  const init = shop?.name
    ?.split(" ")
    .map(function (item: any) {
      return item[0];
    })
    .join("");

  return (
    <Link to="/" className="py-3 flex justify-center">
      <div className="rounded-full">
        <div className="text-3xl text-pink-500">{init}</div>
      </div>
    </Link>
  );
};

export default Logo;
