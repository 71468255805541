import useCustomer from "../../hooks/useCustomer";
import { useAppSelector } from "../../app/hooks";
import { useMutation, gql } from "@apollo/client";
import { UPDATE_CUSTOMER_INFORMATION } from "../../graphql/mutations/mutations";
import { DELETE_CUSTOMER_ADDRESS } from "../../graphql/mutations/mutations";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import {
  RemoveCustomerAddressMutation,
  RemoveCustomerAddressMutationVariables,
} from "../../graphql/mutations/__generated__/mutations";
import AddressModal from "./components/AddressModal";

const PersonalInformation = () => {
  const { userInfo } = useAppSelector((state) => state?.auth);
  const { customer, loading } = useCustomer({
    filter: {
      id: { eq: userInfo?.id },
    },
  });

  console.log(customer);

  const [update, { loading: isLoading }] = useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UPDATE_CUSTOMER_INFORMATION);

  const [deleteAddress, { loading: deletingAddress, error }] = useMutation<
    RemoveCustomerAddressMutation,
    RemoveCustomerAddressMutationVariables
  >(DELETE_CUSTOMER_ADDRESS, {
    refetchQueries: ["GetCustomer"],
  });

  const { register, handleSubmit } = useForm<UpdateCustomerMutationVariables>({
    // defaultValues: {
    //   input: {
    //     name: customer?.name,
    //     email: customer?.email,
    //     phone: customer?.phone,
    //   },
    // },
  });

  const onDeleteClick = (id: any) => {
    deleteAddress({ variables: { removeCustomerAddressId: id } }).then(
      (res) => {}
    );
  };

  const onSubmit: SubmitHandler<UpdateCustomerMutationVariables> = (values) => {
    console.log("values", values);
    const variables = {
      name: values?.input?.name as string,
      email: values?.input?.email as string,
      id: customer?.id.toString(),
      phone: values?.input?.phone as string,
      city: values?.input?.address?.city as string,
      area: values?.input?.address?.area as string,
      landmark: values?.input?.address?.landmark as string,
    };
    update({
      variables: {
        input: {
          ...variables,
        },
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  if (loading) return <div>Loading</div>;

  return (
    // <>
    <div className="flex flex-col md:ml-24 md:bg-white md:shadow-md border-x md:pl-24 ">
      <div className="text-2xl text-deepBlue font-medium">
        Personal Information
      </div>
      <section className="border bg-gray-200 my-4 max-w-lg md:max-w-3xl"></section>

      {/* profile information form */}
      <div className="flex flex-col md:flex-row my-4">
        <div className="md:mr-10 font-medium text-xl text-deepBlue">
          Profiles
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col md:flex-row md:space-x-12 md:pb-4 md:pt-8 ">
            <div className="flex flex-col  space-y-1 mt-2">
              <div>
                <label
                  htmlFor="fullname"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full name
                </label>
              </div>
              <div>
                <input
                  type="text"
                  {...register("input.name", { required: true })}
                  defaultValue={customer?.name}
                  className="appearance-none block w-full  md:w-72 px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm "
                />
              </div>
            </div>

            <div className="flex flex-col space-y-1 mt-2">
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
              </div>
              <div>
                <input
                  type="email"
                  // placeholder={customer?.email}
                  {...register("input.email", { required: true })}
                  defaultValue={customer?.email ?? ""}
                  className="appearance-none block w-full md:w-72 px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col space-y-1 mt-2">
            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
            </div>
            <div>
              <input
                type="text"
                // placeholder={customer?.phone}
                {...register("input.phone", { required: true })}
                defaultValue={customer?.phone}
                className="appearance-none block w-full md:w-72 px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder:gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>

          {/* <div className="flex flex-col space-y-1 mt-2">
              <div>
                <label
                  htmlFor="location"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location <span>(Optional)</span>
                </label>
              </div>
              <div>
                <input
                  type="text"
                  {...register("input.address.area")}
                  className="appearance-none block w-full md:w-72 px-3 py-1 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
            </div> */}
          <div className="pt-12  float-right md:justify-center">
            <button className=" bg-mainPink px-2 text-white text-sm py-1 rounded-sm">
              Update Information
            </button>
          </div>
        </form>
      </div>

      {/* Address form */}

      <div className="flex flex-col md:flex-row my-4">
        <div className="md:mr-4 font-medium text-xl text-deepBlue">Address</div>

        <div className="px-2 mt-12 mx-10">
          <div className="mt-2 flex flex-col">
            <div className=" -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-3">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          City
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Area
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Landmark
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {customer?.address?.map((address) => {
                        return (
                          <tr key={customer?.id}>
                            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="">
                                  <div className="font-medium text-gray-900">
                                    {address?.city}
                                  </div>
                                  {/* <div className="text-gray-500">
                              product code: `02834
                            </div> */}
                                </div>
                              </div>
                            </td>

                            <td className="whitespace-nowrap  py-4 text-sm text-gray-500">
                              <span className="inline-flex rounded-full  px-2 text-xs font-semibold leading-5 ">
                                {address?.area}
                              </span>
                            </td>
                            <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                              {address?.landmark}
                            </td>
                            <td className="whitespace-nowrap px-2 py-4 text-sm text-gray-500">
                              <div
                                className="text-mainPink"
                                onClick={() => onDeleteClick(address?.id)}
                              >
                                Delete
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                      {/* ))} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
         
              <button 
              className=" float-right bg-mainPink px-2 text-orange-500 text-sm py-1 rounded-sm"
              // onClick={}
              >
                Add new address
              </button>
          
          <AddressModal />
        </div>
        {/* test end */}
      </div>
    </div>

    // </>
  );
};

export default PersonalInformation;
