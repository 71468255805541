import { useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { CREATE_CUSTOMER_ADDRESS } from "../../../graphql/mutations/mutations";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  CreateCustomerAddressMutation,
  CreateCustomerAddressMutationVariables,
} from "../../../graphql/mutations/__generated__/mutations";
import { useAppSelector } from "../../../app/hooks";
import useCustomer from "../../../hooks/useCustomer";

const AddressModal = () => {
  const { userInfo } = useAppSelector((state) => state?.auth);
  const { customer, loading } = useCustomer({
    filter: {
      id: { eq: userInfo?.id },
    },
  });

  console.log(customer);

  const [create, { loading: creatingAddress, error }] = useMutation<
    CreateCustomerAddressMutation,
    CreateCustomerAddressMutationVariables
  >(CREATE_CUSTOMER_ADDRESS);

  const { register, handleSubmit } =
    useForm<CreateCustomerAddressMutationVariables>({});

  const onSubmit: SubmitHandler<CreateCustomerAddressMutationVariables> = (
    values
  ) => {
    console.log("values", values);
    const variables = {
      //  customerId: userInfo?.id as string,
      city: values?.input?.city as string,
      area: values?.input?.area as string,
      landmark: values?.input?.landmark as string,
    };
    create({
      variables: {
        customerId: userInfo?.id as string,
        input: {
          ...variables,
        },
      },
    })
      .then((res) => {
        console.log(res);
        setShowModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div className="float-right ">
      <button
        className="bg-mainPink text-white active:bg-mainPink
         px-3 py-1 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
        type="button"
        onClick={() => setShowModal(true)}
      >
        Add a new address
      </button>
      {showModal ? (
        <>
          <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t ">
                  <h3 className="text-xl font=semibold">Add a new address</h3>
                  <button
                    className="bg-transparent border-0 text-black float-right"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-black opacity-7 h-6 w-6 text-xl block bg-gray-400 py-0 rounded-full">
                      x
                    </span>
                  </button>
                </div>
                <div className="relative p-6 flex-auto">
                  <form
                    className="bg-white rounded px-8 pt-6 pb-8 w-full"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <label className="block text-black text-sm font-bold mb-1">
                      Area
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      {...register("input.area", { required: true })}
                    />
                    <label className="block text-black text-sm font-bold mb-1">
                      City
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      {...register("input.city", { required: true })}
                    />
                    <label className="block text-black text-sm font-bold mb-1">
                      Landmark
                    </label>
                    <input
                      className="shadow appearance-none border rounded w-full py-2 px-1 text-black"
                      {...register("input.landmark", { required: true })}
                    />

                    <button
                      className="text-white bg-mainPink font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="submit"
                      //  onClick={() => console.log('kofi')}
                    >
                      Submit
                    </button>
                  </form>
                </div>
                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                  <button
                    className="text-mainPink background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  {/* <button
                      className="text-white bg-mainPink font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                      type="submit"
                      //  onClick={() => console.log('kofi')}
                    >
                      Submit
                    </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default AddressModal;
