import React, { Fragment } from "react";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import Logo from "../../../components/Logo/Logo";
import { useAppSelector } from "../../../app/hooks";
import 'react-whatsapp-widget/dist/index.css';
import 'react-whatsapp-widget/dist/index.css';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import useUser from "../../../hooks/useUser";
import { GET_USER } from "../../../graphql/queries/queries";
import { ReactComponent as CompanyIcon } from '../../../assets/logo.svg';

type Props = {};

const Footer = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { user, loading } = useUser({
    
    userId:shop?.ownerId!
  
});
  return (
    <Fragment>
      <footer aria-labelledby="footer-heading" className="bg-black mt-auto   w-full ">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="mx-auto max-w-7xl px-4 py-8  lg:px-8">
          {/* <div className="flex">
            <Logo  />
          </div> */}
          <div className="flex  text-center justify-center items-center flex-col lg:flex-row lg:justify-evenly text-white">
            <div className="text-white text-center">
              <div className="text-xl lg:text-2xl font-medium">We're Always Here To Help</div>
              <div className=" text-xs lg:text-sm">Reach out to us through any of these support channels</div>
            </div>
            <div className="flex flex-row  mt-2 space-x-4 text-center">
              <div><PhoneIcon className="w-4 h-4 mt-2.5 lg:mt-1.5"/></div>
              <div className="flex flex-col">
                <div className="text-sm">Phone Support</div>
                <div className="font-light">{user?.phone}</div>
              </div>
            </div>
            <div className="flex flex-row mt-2 space-x-5">
            <div><EnvelopeIcon className="w-4 h-4 mt-2.5 lg:mt-1.5"/></div>
              <div className="flex flex-col">
                <div className="text-sm">Email Support</div>
                <div className="font-light">Send Email</div>
              </div>
            </div>

            <div className="flex flex-row ">
              <div className="absolute lft-24 text-black"><WhatsAppWidget  phoneNumber={user?.phone}  CompanyIcon={CompanyIcon} /></div>
                
                
            </div>

          </div>
          <div className=" border-gray-800 pt-10">
            <p className="text-sm font-light text-white">
              Copyright &copy; 2023 {shop?.sokoWorldDomain}.
            </p>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
