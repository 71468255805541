import { useMutation } from "@apollo/client";
import { CREATE_ORDER } from "../graphql/mutations/mutations";
import { CreateOrderMutation, CreateOrderMutationVariables } from "../graphql/mutations/__generated__/mutations";

export const useCreateOrder = () => {
    const [createOrder, { loading, error }] = useMutation<CreateOrderMutation, CreateOrderMutationVariables>(CREATE_ORDER, {

    });

    return {
        createOrder,
        loading, error
    }
};
