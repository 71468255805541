import React, { forwardRef } from "react";
import clsx from "clsx";

type TextInputProps = {
  className?: string;
  labelFor?: string;
  label?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  ({ className, label, ...props }, ref) => {
    return (
      <div>
        <label
          htmlFor={props?.name}
          className="block text-sm font-medium lg:text-black"
        >
          {label}
        </label>
        <input
          ref={ref}
          className={clsx(
            "placeholder:inter-base-regular placeholder-grey-40 py-3 focus:outline-none sm:text-sm block w-full rounded-md border-gray-300 shadow-sm",
            className
          )}
          {...props}
        />
      </div>
    );
  }
);

export default TextInput;
