import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from 'react-helmet-async';
import Footer from "./components/Footer";
import HeroSection from "./components/HeroSection";
import { WhatsAppWidget } from 'react-whatsapp-widget';
import { ReactComponent as CompanyIcon } from '../../assets/logo.svg';
import 'react-whatsapp-widget/dist/index.css';
import { useAppSelector } from "../../app/hooks";
import { GET_USER } from "../../graphql/queries/queries";
import useUser from "../../hooks/useUser";



type Props = {};

const StoreFront = (props: Props) => {
  const { shop } = useAppSelector((state) => state?.shop);
  const { userInfo } = useAppSelector((state) => state.auth);
  // console.log(userInfo?.reseller?.phone,"reseller phone");
  // console.log(shop?.ownerId, "owner");
  
  const { user, loading } = useUser({
    
      userId:shop?.ownerId!
    
  });
  // console.log(user?.phone,"phone")
 
  
  return (
    <Fragment>
      <Helmet>
        {/* <title>Welcome to {shop?.name}</title> */}
        {/* <meta name='description' content="Welcome to my shop "/> */}
        <link rel="icon" type="image/png" href="https://d3ug0vbiixnxyq.cloudfront.net/logos/40ctl72xehiurg0pyzukl7-168503038246.jpeg"/>
        <meta name="og:image" data-react-helmet="true" content="https://d3ug0vbiixnxyq.cloudfront.net/logos/40ctl72xehiurg0pyzukl7-168503038246.jpeg"/>
      </Helmet>
      <div className="bg-[#F8FAFC] flex flex-col min-w-screen  min-h-screen">
        <HeroSection onSearch={function (searchTerm: string): void {
          throw new Error("Function not implemented.");
        } } />
        <Outlet />
        {/* <div className="text-orange-500 fixed bottom-0 right-16 mb-10 mr-8">Need help?</div> */}
        {/* <WhatsAppWidget phoneNumber={user?.phone}  CompanyIcon={CompanyIcon} /> */}
        <Footer  />
      </div>
    </Fragment>
  );
};

export default StoreFront;
