import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Customer } from "../../graphql/types";

interface IAuthFace {
  userInfo: Customer | null;
  token: string;
}

const initialState: IAuthFace = {
  userInfo: null,
  token: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signinUser: (state, { payload }: PayloadAction<any>) => {
      state.userInfo = payload?.user;
      state.token = payload?.token;
    },
    signOut: (state) => {
      state.userInfo = null;
      state.token = "";
    },
  },
});

export const { signinUser, signOut } = authSlice.actions;

export default authSlice.reducer;
