import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { useCreateDigitalOrder } from "../../hooks/useCreateDigitalOrder";
import Loader from "../../layouts/StoreFront/components/Loader";
import { useProduct } from "../../hooks/useProduct";

const ConfirmDigitalOrderPayment = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { userInfo } = useAppSelector((state) => state.auth);
  const { createDigitalOrder, loading, error } = useCreateDigitalOrder();
  const { shop } = useAppSelector((state) => state.shop);
  const [orderSuccess, setOrderSuccess] = useState(false);
  const { id, qty } = useParams();
  const { product, refetch } = useProduct({
    "filter": {
      "id": {
        "eq":id,
      },
      // shop: {
      //   shop_id: {
      //     eq: shop?.id,
      //   },
      // },
    },
  });

  useEffect(() => {
    let isMounted = true;
    if (searchParams.get("success") === "true" && isMounted) {
      // const subTotal = cartItems.reduce(
      //   (acc, curr) =>
      //     acc +
      //     _.sum([
      //       _.multiply(curr.price, curr?.quantity),
      //       _.multiply(curr?.profit_added ?? 0, curr?.quantity),
      //     ]),
      //   0
      // );

      // const totalAmount = _.sum([
      //   subTotal,
      //   parseFloat(customer_delivery_location?.price),
      // ])
      //console.log(customer_address);
      //console.log(customer_address?.id);
      createDigitalOrder({
        variables: {
          input: {
            customerId: userInfo!.id,
            deliveryMode: "SMS",
            digitalDetails: "details",
            paymentReference: null,
            paymentStatus: "PAID",
            price: 2.0,
            productId:id!,
            profitAdded: product ? product.profit_added || 0 : 0,
            resellerId: userInfo!.reseller!.id,
            status: "PENDING",
            supplierId: product ? product.supplier_id : "",
          },
        },
        
      })
      
        .then((res) => {
          console.log("Order Success:", res);
          if (isMounted) { 
            
            const url = new URL(res.data!.createDigitalOrder);
const searchParams = new URLSearchParams(url.search);
            
            console.log(searchParams)
            const invoice = searchParams.get("invoice");
            console.log(invoice)
            setOrderSuccess(true);
            navigate(`/digital-order-success/${id}/${invoice}`);  
          }
        })
        .catch((err) => {
          console.log("--ERROR--", err);
        });
    } else {
      navigate("/");
    }
    return () => {
      isMounted = false; // Set isMounted to false when the component is unmounted
    };
  }, []);


  if (loading) {
    return <Loader />;
  }
  if (error && !orderSuccess) {
    return (
      <div className="space-y-4 w-full">
        <h1>Sorry Order placement couldn't go through for a reason.. try again</h1>

      </div>
    );
  }
  return null;
};

export default ConfirmDigitalOrderPayment;
