
import clsx from "clsx";
import _ from "lodash";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../../features/cart/cart-slice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";


type Props = {
  product: any;
};

const ProductCard = ({ product }: Props) => {
  const { cartItems } = useAppSelector((state) => state.cart);
  const { shop } = useAppSelector((state) => state?.shop);
  const dispatch = useAppDispatch();
 
  const alreadyAdded = !_.isEmpty(
    _.find(cartItems, (el) => el.id === product?.id)
  );

  function limitTitle(sentence: string, maxWidth: number) {
    if (sentence.length <= maxWidth) {
      return sentence;
    } else {
      return sentence.slice(0, maxWidth - 3) + '...';
    }
  }
  const originalSentence = product?.title;
const maxWidth = 20;
const limitedTitle = limitTitle(originalSentence, maxWidth);

  return (
    <div className="group relative flex flex-col  lg:w-72   overflow-hidden rounded-lg  mb-8  bg-white">
      
      <div className="aspect-w-36  aspect-h-4  group-hover:opacity-75 sm:aspect-h-4 lg:h-72 sm:h-32">
      <Link
          to={{
            pathname: `/product/${product?.id}`,
          }}
        >
        <img
          src={product?.avatar}
          alt={product?.title}
          className="h-52 lg:h-64 w-full sm:w-48 lg:w-64 border-2  object-fit "
        />
        </Link>
      </div>
      {/* <div className="flex flex-1 flex-col space-y-2 p-4"> */}
        <div className="flex flex-1 space-y-2  ">
        
          <div className="flex flex-col space-y-2 mx-2 lg:mx-4">
            <div>
            <Link
          to={{
            pathname: `/product/${product?.id}`,
          }}
        >
            <h3 aria-hidden="true" className="text-sm font-medium   text-gray-900 ">
            {/* <button > */}
              {/* <span aria-hidden="true" className="absolute inset-0"></span> */} 
              {limitedTitle}
             {/* </button> */}
          </h3>
          </Link>
        
            </div>
            
          
          <div>
          <p className=" font-bold pt-0.5 text-gray-900  text-xs">
              {product?.currency}{" "}
              {_.sum([product?.price, product?.profit_added])}
            </p>
          </div>
          {/* <div>
            ratings
          </div> */}
          
            <div>
            <button
              onClick={() => {
                alreadyAdded
                  ? dispatch(removeFromCart(product))
                  : dispatch(addToCart(product));
              }}
              className={clsx({
                "text-black w-40 px-2 py-1 bg-white border-2  lg:w-56  border-gray-500 rounded-md text-xs": !alreadyAdded,
                "text-white px-2 py-1 bg-pink-500 rounded-md w-40  lg:w-56 border-gray-500 text-xs": alreadyAdded,
              })}
            >
              {!alreadyAdded ? "Add to Cart" : "Remove"}
            </button>
            </div>

          </div>
           
       
        </div>
     </div>
  );
};

export default ProductCard;
