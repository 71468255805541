import { NavLink, useParams } from "react-router-dom";
import SuccessIcon from "../../assets/greenmark.png";
import { useMutation } from "@apollo/client";
import { REQUEST_OTP,REQUEST_ORDER_OTP } from "../../graphql/mutations/mutations";
import { useNavigate } from "react-router-dom";
import { useRequestOTP } from "../../hooks/useRequestOTP";
import { RequestOtpMutation,RequestOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { GetOrderOtpMutation,GetOrderOtpMutationVariables } from "../../graphql/mutations/__generated__/mutations";
import { useAppSelector } from "../../app/hooks";
import { ChannelType } from "../../graphql/types";



const DigitalOrderSuccessPage = () => {
  const { id } = useParams();
  const {invoice} =useParams();
  const navigate = useNavigate();
  const { userInfo } = useAppSelector((state) => state.auth);
  const [requestOTP, { loading: requestingOTP }] = useMutation<
RequestOtpMutation,
RequestOtpMutationVariables
>(REQUEST_OTP);
const [getOTP, { loading: gettingOTP }] = useMutation<
GetOrderOtpMutation,
GetOrderOtpMutationVariables
>(REQUEST_ORDER_OTP);
  const request = ()=>{
    getOTP({
      variables:{
            "orderId":invoice!,
            "customerId":userInfo!.id
      }})
      .then((res)=>{
        if (res.data?.getOrderOtp) {
          navigate(`/input-otp/${id}/${invoice}`);
        }
        console.log(res);
      })
      .catch((err)=>{
        console.log(err);
      })
  }
  return (
    <>
      <div className="min-h-full pb-12 flex flex-col bg-white my-11">
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <span className="inline-flex">
              {/* <span className="sr-only">Workflow</span> */}
              <img src={SuccessIcon} className="w-32 h-32" alt="" />
            </span>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
              <h1 className="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                Payment Completed
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Payment has been completed and  your order has been placed  successfully. Proceed to preview your order.
              </p>
              <div className="mt-6">
                <button className="px-8 py-2 text-sm bg-pink-600 text-white rounded-full" onClick={request}>Continue</button>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default DigitalOrderSuccessPage;
