import { useQuery } from "@apollo/client"
import { GET_SHOP_COLLECTION } from "../graphql/queries/queries"
import { GetShopCollectionQuery, GetShopCollectionQueryVariables } from "../graphql/queries/__generated__/queries"

export const useShopCollection = (variables?: GetShopCollectionQueryVariables) => {
    const { data, loading, error,  networkStatus, fetchMore } = useQuery<GetShopCollectionQuery, GetShopCollectionQueryVariables>(GET_SHOP_COLLECTION, {
        variables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
    })
    return {
        collection: data?.getShopCollection ,
        loading,
        error,
        networkStatus,
        fetchMore
    }
}