import { useQuery } from "@apollo/client";
import { GET_SHOP } from "../graphql/queries/queries";
import { GetShopQuery, GetShopQueryVariables } from "../graphql/queries/__generated__/queries";


const useShop = (variables: GetShopQueryVariables) => {
  const { data, error, loading } = useQuery<GetShopQuery, GetShopQueryVariables>(GET_SHOP, {
    variables,
    notifyOnNetworkStatusChange: true
  });

  return {
    shop: data?.getShop,
    error,
    loading,
  };
};

export default useShop;
