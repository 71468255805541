import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { useProduct } from "../../hooks/useProduct";
import useProductReviews from "../../hooks/useProductReviews";
import useReviewLength from "../../hooks/useReviewLength";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addToCart, decreaseQty, increaseQty, removeFromCart,setCustomerAddress } from "../../features/cart/cart-slice";
import { Helmet } from "react-helmet-async";
import _ from "lodash";
import numeral from "numeral";
import { Fragment, useState } from "react";
import { MinusIcon, PlusIcon, StarIcon } from "@heroicons/react/24/outline";
import {UserIcon} from "@heroicons/react/24/outline";

const DigitalProductDetail: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { cartItems } = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();
  const { shop } = useAppSelector((state) => state?.shop);
  const[qty,setQty]=useState(1);
  const { product } = useProduct({
    filter: {
      id: {
        eq: id,
      },
      shop: {
        shop_id: {
          eq: shop?.id,
        },
      },
    },
  });
  const productid=product?.id;
  console.log(productid)
  const {reviews}=useProductReviews({
    
      filter: {
      id:{
        eq:productid
      },
        product_id: {
          eq:productid
        }
      },
      // pagination: {
      //   limit: 0,
      //   offset: 0
      // }
    
  
  });
  const {reviewlength}=useReviewLength({
    filter:{
      product_id:{
        eq:productid
      }
    }
  });
  

  const alreadyAdded = !_.isEmpty(
    _.find(cartItems, (el) => el.id === product?.id)
  );

  
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }


  return (
    <div className="bg-white">
      
      <Helmet>
      {/* <title> {product?.title}</title> */}
      <link rel="icon" type="image/png" href={product?.avatar}/>
      <meta property='og:title' content={product?.title}/>
        <meta property='og:description' content={shop?.name +"'s shop  - "+ product?.title }/>
        <meta property="og:image" itemProp="image"  content={product?.avatar}/>
        <meta property="og:type" content="website"/>
        <meta property="og:image:type" content="image/png"/>
        <meta property="og:image:width" content="300"/>
        <meta property="og:image:height" content="300"/>  
      </Helmet>
      <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            <div className="mx-auto mt-6  w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product?.media?.map((image: any) => (
                  <Tab
                    key={image?.id}
                    className="relative flex lg:h-24 h-16 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none  focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img
                            src={image?.avatar ?? ""}
                            alt=""
                            className="h-full w-full object-cover object-center"
                          />
                        </span>
                        <span
                          className={clsx(
                            selected ? "ring-2 ring-pink-500" : "ring-transparent",
                            "pointer-events-none absolute inset-0 rounded-md  ring-offset-2"
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-w-1 aspect-h-1 w-full">
              {product?.media?.map((image: any) => (
                <Tab.Panel key={image?.id}>
                  <img
                    src={image?.avatar ?? ""}
                    alt={product?.title}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-xl lg:text-3xl font-medium tracking-tight text-gray-900">
              {product?.title}
            </h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">
                <>
                  {product?.currency}{" "}
                  {numeral(
                    _.sum([product?.price ?? 0, product?.profit_added ?? 0])
                  ).format("#,#")}
                </>
              </p>
            </div>
            <div className="flex mt-4 mb-4 flex-row space-x-4">
                  <div className="rounded-full bg-pink-500 text-white px-4 py-1.5">Full Copy</div>
                  <div className="rounded-full border border-gray-400 px-4 py-1.5">Commentory</div>
            </div>

            <div className="border flex justify-between w-16 lg:w-24 rounded-md border-gray-400 p-0.5 lg:p-1 items-center">
                          <button
                           onClick={() => setQty(qty - 1)}
                           >
                            <MinusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                          {/* <div>Qty:</div> */}
                          <div>{qty}</div>
                          <button
                            onClick={() => {
                              setQty(qty+1)
                            }}
                          >
                            <PlusIcon className="h-4 w-4 lg:h-6 lg:w-6" />
                          </button>
                        </div>

           

            <form className="mt-6">
              <div className="sm:flex-col mt-10 flex">
              {/* <div 
              className="bg-black text-center p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white w-full flex-1"
               onClick={()=>navigate("/digital-product-delivery")}>Order</div> */}

                <button
                  type="button"
                  onClick={()=>navigate(`/digital-product-delivery/${product?.id}/${qty}`)}
                  className="bg-pink-500 text-center p-3 hover:bg-white rounded-full hover:border hover:border-black hover:text-black text-white max-w-xs flex-1"
                >
                  {!alreadyAdded ? "Proceed to Checkout" : "Remove from cart"}
                </button>
                
              </div>
            </form>
            <div className="mt-6">
              <h3 className="font-medium">Product Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: product?.description ?? "" }}
              />
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>
            </section>
          <section>
              
             

            <div className="mx-auto mt-16 w-full flex flex-row space-x-8 max-w-2xl lg:col-span-4 lg:mt-0 lg:max-w-none">
              <div className="font-medium text-lg">Ratings & Reviews</div>
              <div>{product?.overalReview}</div>
              <div className=" flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <StarIcon
                              key={rating}
                              className={classNames(
                                product?.overalReview! > rating ? 'text-yellow-500' : 'text-gray-300',
                                'h-5 w-5 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
              </div>
              {/* <div className="flex flex-col mt-4 space-y-2">
                  <div className="flex flex-row space-x-4  items-center">
                    <div className="w-24">Excellent</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.excellent}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Very Good</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.veryGood}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Good</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.good}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Average</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.average}</div>
                  </div>
                  <div className="flex flex-row space-x-4 items-center">
                    <div className="w-24">Poor</div>
                    <div className="w-52 h-2 bg-slate-300 rounded-lg"></div>
                    <div>{product?.reviewBreakDown.breakDown.poor}</div>
                  </div>
              </div> */}
              <div className="flex flex-col mt-4 space-y-2">
  {[
    { label: 'Excellent', value: product?.reviewBreakDown.breakDown.excellent },
    { label: 'Very Good', value: product?.reviewBreakDown.breakDown.veryGood },
    { label: 'Good', value: product?.reviewBreakDown.breakDown.good },
    { label: 'Average', value: product?.reviewBreakDown.breakDown.average },
    { label: 'Poor', value: product?.reviewBreakDown.breakDown.poor }
  ].map(({ label, value }) => (
    <div className="flex flex-row space-x-4 items-center" key={label}>
      <div className="w-24">{label}</div>
      <div className="w-52 h-2 bg-slate-300 rounded-lg relative">
  <div
    className="h-2 bg-yellow-500 rounded-lg absolute left-0 top-0"
    style={{ width: `${(value! / product?.reviewBreakDown.totalReviews!) * 100}%` }}
  />
</div>
<div>{value}</div>
    </div>
  ))}
</div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DigitalProductDetail;
