import { useAppSelector } from "./../app/hooks";

const useCart = () => {
  const {
    cartItems,
    cartTotal,
    customer_address,
    customer_delivery_location,
    deliveryPrice,
  } = useAppSelector((state) => state.cart);
  console.log(cartTotal, "cartTotal");
  return {
    cartItems,
    cartLength: cartItems?.length,
    total: cartTotal,
    deliveryPrice,
    customer_address,
    customer_delivery_location,
  };
};

export default useCart;
